import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const { color } = props;

  const defaultColor = '#0B060D';
  return (
    <svg
      width={24}
      height={24}
      //transform="scale(2.0)"
      {...props}
    >
      <path
        d="M5.28 7C4.576 7 4 7.6 4 8.333v7c0 .733.576 1.334 1.28 1.334h.99c.15.757.8 1.333 1.57 1.333s1.42-.576 1.57-1.333h6.14c.15.757.8 1.333 1.57 1.333s1.42-.576 1.57-1.333h.35c.526 0 .96-.452.96-1v-2.198c0-.375-.126-.748-.35-1.042l-.89-1.167-.01-.03-1.63-3.324A1.582 1.582 0 0 0 15.7 7H5.28zm0 .667h8.39l.79 3.28c.1.416.44.727.85.761l3.18.26.66.876c.134.176.21.4.21.625v2.198a.321.321 0 0 1-.32.333h-.35c-.15-.758-.8-1.333-1.57-1.333s-1.42.575-1.57 1.333H9.41c-.15-.758-.8-1.333-1.57-1.333S6.42 15.242 6.27 16h-.99c-.357 0-.64-.294-.64-.667v-7c0-.372.283-.666.64-.666zm9.05 0h1.37c.361 0 .686.205.85.541l1.49 3.052-2.68-.218a.314.314 0 0 1-.28-.25l-.75-3.125zm-6.49 7.666c.506 0 .909.403.95.917v.01a.353.353 0 0 0 0 .136v.01c-.037.52-.441.927-.95.927-.524 0-.944-.428-.96-.968a.355.355 0 0 0 0-.073c.021-.537.44-.959.96-.959zm9.28 0c.534 0 .96.444.96 1a.232.232 0 0 0 0 .042c-.021.536-.44.958-.96.958-.524 0-.944-.428-.96-.968a.355.355 0 0 0 0-.073c.021-.537.44-.959.96-.959z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string
};

SvgComponent.defaultProps = {
  color: '#0b060d'
};

export default SvgComponent;
