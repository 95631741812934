// @flow

import React from 'react';
import { MDBBtn } from 'mdbreact';
import { IconCheck } from '../../../../assets';
import { Yellow } from '../../../../const';

import { withMobileContext } from '../../../../modules/MobileContext';
import ServiceIcon from '../../Generic/ServiceIcon';

type Props = {
  intl: Object,
  dg: Object,
  booking: Object,
  setService: Function
};

class Services extends React.PureComponent<Props, State> {
  render() {
    const { intl, dg, booking, setService } = this.props;

    const services = [];

    if (dg.services) {
      Object.keys(dg.services).forEach((key) => {
        if (dg.services[key].available === true) {
          services.push({
            id: key.toUpperCase(),
            name: key.toLowerCase(),
            description: dg.services[key].description
          });
        }
      });
    }

    if (services.length === 0) {
      return null;
    }

    return (
      <div className="List">
        {services.map((service) => (
          <MDBBtn
            key={service.id}
            className={`Service align-items-center ${booking.service === service.id ? 'active' : ''}`}
            onClick={() => setService(service.id)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >

            {/* On ajoute l'icône du service */}
            {booking.service !== service.id ? (
              <div className="Picto">
                {ServiceIcon[`${service.name}`]}
                &nbsp;
              </div>
            ): null}
            <div className="my-auto">{intl.formatMessage({ id: `course.service.${service.name}` })}</div>
            {booking.service === service.id ? (
              <div className="Picto">
                <IconCheck color={Yellow} />
              </div>
            ) : null}
          </MDBBtn>
        ))}
      </div>
    );
  }
}

export default withMobileContext(Services);
