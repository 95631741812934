import API from '../../components/Api';
import CTA from '../../components/UI/Generic/CTA';
/**
 * Créer une nouvelle facture à partir d'un devis
 * @param {String} quotation_id 
 */
export default async function createFromQuotation(
    quotation_id: String,
    toastManager: Object
) {
    const data = await API.get(`quotations/${quotation_id}`);

    let newInvoice = data.data;
    delete newInvoice._id;
    delete newInvoice.createdAt;
    delete newInvoice.updatedAt;
    delete newInvoice.options;
    delete newInvoice.options;
    newInvoice.status = 'OPEN';
    newInvoice.terms = "ON_RECEIPT";

    let issue_date = new Date().toISOString().split('T')[0];
    let year = issue_date.split('-')[0];
    let month = issue_date.split('-')[1];
    let day = issue_date.split('-')[2];
    newInvoice.issue_date = issue_date;

    if (newInvoice.sender.legal_entity_id) {
        const le = await API.get(`legal_entities/${newInvoice.sender.legal_entity_id}`);
        newInvoice.sender = le.data;
        newInvoice.sender.bank_name = le.data.bank_account.bank_name;
        newInvoice.sender.account_number = le.data.bank_account.account_number;
        newInvoice.sender.bank_identifier_code = le.data.bank_account.bank_identifier_code;
        newInvoice.sender.name = le.data.legal_name;
        newInvoice.sender.address = le.data.address;
        newInvoice.sender.business_vat_id = le.data.business_vat_id;
        newInvoice.sender.business_tax_id = le.data.business_tax_id;
        newInvoice.sender.share_capital = le.data.share_capital;
        newInvoice.sender.rcs_city = le.data.company_register_location;
        newInvoice.sender.structure = le.data.structure;
        newInvoice.sender.mcc = le.data.mcc;
        newInvoice.sender.payment_conditions = le.data.payment_conditions;
        newInvoice.sender.legal_entity = le.data._id;
        newInvoice.number = year + month + day + '-' + le.data.invoice_number;
    }

    if (newInvoice.caution) {
        newInvoice.deposit = newInvoice.caution;
    }

    const dg = await API.get(`driver-groups/${newInvoice.driver_group}?$select[]=legal_entities`);
    const le = await API.get(`legal_entities/${dg.data.legal_entities}?$select[]=invoice_number`);
    newInvoice.number = year + month + day + '-' + le.data.invoice_number;

    newInvoice.automatic_recovery = false;
    newInvoice.client_entry = '';

    let total = 0;
    newInvoice.items.map((item) => {
        item.total = item.subtotal + item.vat;
        total += item.total;
    })
    newInvoice.total = total;
    newInvoice.sent = newInvoice.receiver.email ? true : false;

    API.post('invoices', newInvoice)
        .then((res) => {
            toastManager.removeAll();
            toastManager.add('Facture créée', {
                appearance: 'success',
                autoDismiss: true
            });

            //Si envoie facture par email 
            if (newInvoice.receiver.email) {
                CTA({
                    target: {
                        value: {
                            type: 'send-invoice-email',
                            to: [res.data, { _id: newInvoice.driver_group, legal_entities: le.data._id }, {}]
                        }
                    }
                })
            }
            return res.data;
        }).catch((err) => {
            return err
        })
}