// @flow

import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import history from './history';
import withClearCache from './ClearCache';

import ToastContainer from './ToastContainer';
import { ToastProvider } from 'react-toast-notifications';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// Redux ------------------------------------
import { setUser } from './actions/users';

import { withMobileContext } from './modules/MobileContext';
import YouCallYouGo from './components/UI/CustomBooking/YouCallYouGo';

const HomePage = lazy(() => import('./routes/HomePage'));
const Quotation = lazy(() => import('./routes/Quotation'));
const Form = lazy(() => import('./routes/Form'));
const Driver = lazy(() => import('./routes/Driver'));
const Medical = lazy(() => import('./routes/Medical'));
const Pec = lazy(() => import('./routes/Pec'));
const Onboarding = lazy(() => import('./routes/Onboarding'));
const ElexiBooking = lazy(() => import('./routes/ElexiBooking'));
const DriverGroup = lazy(() => import('./routes/DriverGroup'));
const BookingFrame = lazy(() => import('./routes/BookingFrame'));
const SmallerBookingFrame = lazy(() => import('./routes/SmallerBookingFrame'));
const Connexion = lazy(() => import('./routes/Connexion'));
const Register = lazy(() => import('./routes/Register'));
const LostPassword = lazy(() => import('./routes/LostPassword'));
const Deconnexion = lazy(() => import('./routes/Deconnexion'));
const Manager = lazy(() => import('./routes/Manager'));
const BusinessManager = lazy(() => import('./routes/BusinessManager'));
const Ride = lazy(() => import('./routes/Ride'));
const Passenger = lazy(() => import('./routes/Passenger'));
const Operator = lazy(() => import('./routes/Operator'));
const Admin = lazy(() => import('./routes/Admin'));
const NotFound = lazy(() => import('./routes/NotFound'));
const Help = lazy(() => import('./routes/Help'));
const AppHome = lazy(() => import('./routes/AppHome'));
const Chat = lazy(() => import('./routes/Chat'));
const TaxiWebsite = lazy(() => import('./routes/TaxiWebsite'));
const PaymentSuccessPage = lazy(() => import('./routes/PaymentSuccessPage'));
const StripePages = lazy(() => import('./routes/StripePages'));
const UnitAutoRegister = lazy(() => import('./routes/UnitAutoRegister'));

type Props = {
  intl: Object,
  sm: boolean,
  md: boolean,
  lg: boolean,
  logUser: Function
};

class App extends React.Component<Props> {
  componentWillMount() {
    const { logUser, sm, md, lg } = this.props;

    let token = null;
    try {
      token = sessionStorage ? sessionStorage.getItem('nebulea_token') : null;
    } catch { }

    try {
      if (!token && localStorage) {
        token = localStorage.getItem('nebulea_token');
      }
    } catch { }

    if (token) {
      const data = { strategy: 'jwt', accessToken: token };
      const mobileContext = { sm, md, lg };

      logUser(data, history, mobileContext);
    }

    this.mouseDownListener();
  }

  componentWillUnmount() {
    sessionStorage.removeItem('nebulea_filters');
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  mouseDownListener = () => {
    // const root = document.getElementById('root');
    // root.addEventListener('mousedown', (e) => {
    //   if (e.button === 3) {
    //     // Block goBack
    //     history.block();
    //   }
    // });
    // root.addEventListener('wheel', (e) => {
    //   if (e.deltaX < 0 || e.deltaX>0) {
    //     // Block goBack
    //     history.block();
    //   }
    // });
  };

  render() {
    const { intl } = this.props;

    //Access ToastProvider from everywhere
    window.__react_toast_provider = React.createRef();

    return (
      <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider}>
        <Router history={history}>
          <Suspense fallback={<div className="root-loader"></div>}>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/driver" exact component={Driver} />
              {/*               <Route path="/business" exact component={Business} />
/*  */}             {/*  <Route path="/medical" exact component={Medical} /> */}
              <Route path="/site-internet-taxi" exact component={TaxiWebsite} />
              <Route path="/elexi" exact component={ElexiBooking} />
              <Route path="/elexi/:businessGroupId" exact component={ElexiBooking} />
              <Route path="/onboarding" exact component={Onboarding} />
{/*               <Route path="/pec" exact component={Pec} />
 */}              <Route path={`/${intl.formatMessage({ id: 'routes.signin' })}`} component={Connexion} />
              <Route path="/connexion" component={Connexion}/>
              <Route path={`/:type/connexion`} component={Connexion} />
              <Route path={`/${intl.formatMessage({ id: 'routes.register' })}`} component={Register} />
              <Route path={`/${intl.formatMessage({ id: 'routes.signout' })}`} component={Deconnexion} />
              <Route path={`/${intl.formatMessage({ id: 'routes.lost-password' })}`} component={LostPassword} />
              <Route
                path={`/${intl.formatMessage({
                  id: 'routes.driver-group'
                })}/:driverGroupId`}
                exact
                component={DriverGroup}
              />
              <Route
                path={`/${intl.formatMessage({
                  id: 'routes.driver-group'
                })}/:driverGroupId/:businessGroupId`}
                exact
                component={DriverGroup}
              />
              <Route
                path={`/youcallyougo`}
                exact
                component={YouCallYouGo}
              />
              <Route
                path={`/${intl.formatMessage({
                  id: 'routes.driver-group'
                })}/:driverGroupId/:service/:departure/:arrival`}
                component={DriverGroup}
              />
              <Route
                path={`/${intl.formatMessage({
                  id: 'routes.frame'
                })}/:driverGroupId/:primaryColor/:secondaryColor`}
                component={BookingFrame}
              />
              <Route
                path="/smallerframe/:driverGroupId"
                component={SmallerBookingFrame}
              />
              {/* Ride */}
              <Route path="/rides/:rideId" exact component={Ride} />
              <Route path="/rides/:action/:rideId" exact component={Ride} />

              {/* Form */}
              <Route path="/forms/:rideId" exact component={Form} />

              {/* Quotation Signature */}
              <Route path="/quotations/:rideId" exact component={Quotation} />

              {/* Paiement success */}
              <Route path="/success_invoice_payment" exact component={PaymentSuccessPage} />

              {/* Passager */}
              <Route path="/passenger" exact component={Passenger} />
              <Route path="/passenger/:type" exact component={Passenger} />

              {/* APP Mobile */}
              <Route path="/home" exact component={AppHome} />
              <Route path="/channel/:channelId" exact component={Chat} />

              {/* Chauffeurs (Operator) */}
              <Route path="/operator" exact component={Operator} />
              <Route path="/operator/:type" exact component={Operator} />
              <Route path="/operator/:type/:action" exact component={Operator} />
              <Route path="/operator/:type/:action/:rideId" exact component={Operator} />

              {/* Manager */}
              <Route path="/manager" exact component={Manager} />
              <Route path="/manager/:type" exact component={Manager} />
              <Route path="/manager/:type/:action" exact component={Manager} />
              <Route path="/manager/:type/:action/:rideId" exact component={Manager} />

              {/* Business */}
              <Route path="/business/:type" exact component={BusinessManager} />
              <Route path="/business/:type/:action" exact component={BusinessManager} />
              <Route path="/business/:type/:action/:rideId" exact component={BusinessManager} />

              {/* Stripe Pages */}
              <Route path="/stripe/:type" component={StripePages} />

              {/* Page de self unit creation */}
              <Route path="/creation-driver/:rideId" component={UnitAutoRegister} />

              {/* Admin */}
              <Route path="/admin" exact component={Admin} />
              <Route path="/admin/:type" exact component={Admin} />
              <Route path="/admin/:type/:action" exact component={Admin} />
              <Route path="/admin/:type/:action/:entityId" exact component={Admin} />

              {/* Help page */}
              <Route path="/help" exact component={Help} />

              {/* 404 page */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ToastProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.infos,
  isFetching: state.isFetching.isFetching,
  errors: state.errors
});

export default connect(mapStateToProps, {
  logUser: setUser
})(withMobileContext(injectIntl(withClearCache(App))));
