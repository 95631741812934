/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { SET_USER, SET_DRIVER_GROUP } from './types';
import { IS_FETCHING, IS_NOT_FETCHING } from '../loading/types';
import { ADD_ERROR, REMOVE_ERROR } from '../errors/types';
import API, { setAuthorization } from '../../components/Api';

import {socket} from '../../socket';

import * as hooks from './hooks';
const { getUserRoles, redirectLoggedUser, subscribeUser } = hooks;

type SignIn = {
  strategy: string,
  password: string,
  remember: boolean,
  email?: string,
  phone?: string
};

/**
 * Authentifie l'utilisateur
 * @param {SignIn} data Informations de connexion
 * @param {Object} history Historique de navigation
 * @param {Object} mobileContext Largeurs d'écran acceptées
 */
export function setUserForAdmin(id: String, history: Object, mobileContext: Object) {
  return (dispatch: Function) => {
    dispatch({ type: IS_FETCHING });
    dispatch({ type: REMOVE_ERROR });


    API.get(`users/${id}`).then(res => {
      const user = res.data;

      // Sauvegarde des infos du user
      user.roles = getUserRoles(user);
      dispatch({
        type: SET_USER,
        values: user
      });
      // S'il y a plusieurs DG, on affecte le premier par défault
      if (user.driver_groups.length > 0) {
        const dg_manager = user.driver_groups.filter((item) => item.role === 'MANAGER');
        let dg_id = "";
        if (dg_manager.length > 0) {
          dg_id = dg_manager[0]._id;
        } else {
          dg_id = user.driver_groups[0]._id;
        }
        API.get(`driver-groups/${dg_id}`).then((resDG) => {
          dispatch({
            type: SET_DRIVER_GROUP,
            values: resDG.data
          });
        });
      }

      dispatch({ type: REMOVE_ERROR });

      // On redirige l'utilisateur vers son interface
      redirectLoggedUser(user, history, mobileContext);
    })
  };
}
