// @flow
import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { injectIntl } from 'react-intl';

// Redux ------------------------------------
import { connect } from 'react-redux';
import { withMobileContext } from '../../../modules/MobileContext';
import { setUser } from '../../../actions/users';
import { removeError } from '../../../actions/errors';
import API from '../../Api';

import Header from './Header';
import Description from './Description';
import Photos from './Photos';
import Services from './Services';
import FollowUs from './FollowUs';
import Legal from './Legal';
import Booking from './Booking';
import UserRides from './UserRides';
import { BookingPlatformAllowed } from '../../../const';

type Props = {
  user: Object,
  intl: Object,
  history: Object,
  match: {
    params: {
      driverGroupId: string,
      businessGroupId: string
    }
  },
  toastManager: Object
};

type State = {
  dg: Object
};

class DriverGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dg: {},
      bg: null,
      rides: []
    };

    this.getDriverGroup();
    this.getBusinessGroup();
  }

  componentDidMount() {
    const { user, match, driver_group } = this.props;
    if (user && user._id && (driver_group || match.params.driverGroupId)) {
      if (BookingPlatformAllowed.indexOf(driver_group || match.params.driverGroupId) > -1) {
        this.getUserRides();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user, match, driver_group } = this.props;
    if (prevProps.user === null && user && user._id && (driver_group || match.params.driverGroupId)) {
      if (BookingPlatformAllowed.indexOf(driver_group || match.params.driverGroupId) > -1) {
        this.getUserRides();
      }
    }
  }

  getUserRides = () => {
    const { user, match, driver_group } = this.props;

    API.get(`rides/?driver_group=${driver_group || match.params.driverGroupId}&passenger=${user._id}&status[$nin]=DELETED&$sort[departure_date]=-1`).then(res => {
      this.setState({ rides: res.data.data });
    })
  }

  getDriverGroup = () => {
    const { match, toastManager, driver_group } = this.props;

    if (match.params.driverGroupId || driver_group) {
      //Si c'est Elexi Bordeaux, on redirect vers Transports de Fred
      if (match.params.driverGroupId === "5d682842380f2c3888ae7d93") {
        match.params.driverGroupId = "61750c1a73aabb77e3ae8fd7";
      }
      if (driver_group) {
        match.params.driverGroupId = driver_group;
      }

      API.get(`driver-groups/${match.params.driverGroupId}`)
        .then(res => {
          const { data } = res;

          if (data.archived === true) {
            this.setState({
              archived: true
            });
            return;
          }

          this.setState({
            dg: data
          });
        })
        .catch(err => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
    }
  };


  getBusinessGroup = () => {
    const { match, toastManager } = this.props;

    if (match.params.businessGroupId) {
      API.get(`business-groups/${match.params.businessGroupId}`)
        .then(res => {
          const { data } = res;
          // console.log(data);
          this.setState({
            bg: data
          });
        })
        .catch(err => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
    }
  };

  render() {
    const { dg, bg, archived, rides } = this.state;

    if (archived) {
      return <p>Ce compte est inactif, pour plus de renseignements contactez Nebulea.eu</p>
    } else {
      return (
        <div className="DriverGroup">
          <Booking {...this.props} dg={dg} bg={bg} />
          <UserRides {...this.props} rides={rides} />
          <Header {...this.props} dg={dg} bg={bg} />
          <Description {...this.props} dg={dg} />
          <Photos {...this.props} dg={dg} />
          <Services {...this.props} dg={dg} />
          <FollowUs {...this.props} dg={dg} />
          <Legal {...this.props} dg={dg} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.users.infos,
  isFetching: state.isFetching.isFetching,
  errors: state.errors
});

export default connect(mapStateToProps, {
  logUser: setUser,
  removeErrorLogin: removeError
})(withMobileContext(injectIntl(withToastManager(DriverGroup))));
