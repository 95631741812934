// @flow

import React from 'react';

// l10n
import { FormattedMessage } from 'react-intl';

import { IconArrowUp, IconArrowDown } from '../../../assets';

type Props = {
  dg: Object
};

type State = {
  isOpen: boolean
};

class Legal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { dg } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="Legal Card" id="legal">
        <div
          className="Button"
          onClick={this.toggle}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          <FormattedMessage
            id="driver-group.titles.legal"
            values={{ brand: dg.brand }}
          />
          {isOpen ? <IconArrowUp /> : <IconArrowDown />}
        </div>
        <div className={`Content ${isOpen ? '' : 'hidden'}`}>
          {dg.standard_form_contract}
        </div>
      </div>
    );
  }
}

export default Legal;
