// @flow
import React from 'react';

import { withMobileContext } from '../../../modules/MobileContext';
import { injectIntl } from 'react-intl';
import TravelCard from '../Generic/Rides/TravelCard';
import { MDBRow } from 'mdbreact';

class UserRides extends React.PureComponent<Props> {
  render() {
    const { rides } = this.props;

    if (rides.length > 0) {
      return (
        <MDBRow className='m-0 py-3 px-5 overflow-y-scroll flex-column' style={{ maxHeight: 250 }}>
          <MDBRow className='py-2 px-1 mr-5' style={{ fontSize: '1.3rem' }}><b>Mes réservations</b></MDBRow>
          {rides.map((item) => (
            <TravelCard
              key={item._id}
              data={item}
              to={`/rides/${item._id}`}
              showDriver={true}
              showStatus={true}
              {...this.props} />
          ))}
        </MDBRow>
      )
    } else {
      return null
    }
  }
}

export default withMobileContext(injectIntl(UserRides));
