// @flow

import React from 'react';

import GeoCoder from '../../../../Map/GeoCoder';

import { IconCircle, IconLine, IconLocation } from '../../../../../assets';

import { Yellow } from '../../../../../const';
import ErrorMessage from '../../../Generic/ErrorMessage';

type Props = {
  intl: Object,
  dg: Object,
  booking: Object,
  activeTab: String,
  setPoint: Function,
  setService: Function,
  handleBookingInputChange: Function,
  increasePassenger: Function
};

class Address extends React.PureComponent<Props, State> {
  render() {
    const { intl, dg, booking, setPoint, errors } = this.props;

    return (
      <div className="Address">
        <div
          className="d-flex flex-column justify-content-around bg-secondary rounded-right position-absolute"
          style={{ top: '40px', left: 0, height: '112px' }}
        >
          <div className="rounded-circle d-flex justify-content-center aligns-items-center">
            <IconCircle color={Yellow} />
          </div>
          <div className="rounded-circle d-flex flex-column justify-content-center aligns-items-center">
            <IconLine color={Yellow} />
          </div>
          <div className="rounded-circle d-flex justify-content-center aligns-items-center">
            <IconLocation color={Yellow} />
          </div>
        </div>
        <div className='ml-3'>
          <GeoCoder
            proximity={{
              longitude: dg.location ? dg.location.coordinates[0] : null,
              latitude: dg.location ? dg.location.coordinates[1] : null
            }}
            address={booking.departure_address}
            placeHolder={intl.formatMessage({
              id: 'driver-group.booking.form.departure_address'
            })}
            onChange={(e) => setPoint(e, 'departure_address')}
            className="mt-4"
          />
          <ErrorMessage
            content={intl.formatMessage({
              id: 'driver-group.booking.error.departure'
            })}
            isVisible={errors && errors.departure_address}
          />
          <GeoCoder
            proximity={{
              longitude: dg.location ? dg.location.coordinates[0] : null,
              latitude: dg.location ? dg.location.coordinates[1] : null
            }}
            address={booking.arrival_address}
            placeHolder={intl.formatMessage({
              id: 'driver-group.booking.form.arrival_address'
            })}
            onChange={(e) => setPoint(e, 'arrival_address')}
            className="mt-4"
          />
          <ErrorMessage
            content={intl.formatMessage({
              id: 'driver-group.booking.error.arrival'
            })}
            isVisible={errors && errors.arrival_address}
          />
        </div>
      </div>
    );
  }
}

export default Address;
