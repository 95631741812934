import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M11.8 4c3.914 0 7.154 2.976 7.707 6.835-.46-.387-1.03-.627-1.64-.627-.086 0-.167.026-.25.035-.743-2.577-3.067-4.465-5.817-4.465-3.345 0-6.067 2.79-6.067 6.222 0 3.431 2.722 6.222 6.067 6.222l.015-.002-.753 1.325c-.071.126-.11.264-.16.398C7.021 19.484 4 16.099 4 12c0-4.41 3.5-8 7.8-8zm-.867 3.556h1.734v4.812l-2.854 2.927-1.226-1.257 2.346-2.406V7.556zm4.667 2.666a.362.362 0 0 1 .295.154c.658.757 1.905 2.587 1.905 4.359.57-.424.739-1.19.74-1.195v-.001a.37.37 0 0 1 .36-.308.367.367 0 0 1 .342.24v.001c.317.688.758 2.24.758 3.143 0 2.214-1.54 3.258-1.6 3.298a.368.368 0 0 1-.233.087.369.369 0 0 1-.358-.295c-.414-1.802-2.47-2.022-1.842-3.842 0 0-1.1.444-1.1 1.65 0 .736.63 1.541.964 1.819a.38.38 0 0 1 .136.292.371.371 0 0 1-.443.368c-.744-.084-2.857-1.377-2.857-3.377 0-2.632 2.566-4.115 2.566-6.017 0-.207.164-.376.367-.376z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
