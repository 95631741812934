import { combineReducers } from 'redux';

// REDUCERS
import usersReducer from './usersReducer';
import errorsReducer from './errorsReducer';
import loadingReducer from './loadingReducer';
import searchPassengerReducer from './searchPassengerReducer';

const reducers = combineReducers({
  users: usersReducer,
  errors: errorsReducer,
  isFetching: loadingReducer,
  searchPassenger: searchPassengerReducer
});

export default reducers;
