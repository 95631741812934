/**
 * Crée un nouveau chauffeur
 * @param {Object} invoice historique
 */
export default function nameQuotation(
    quotation: Object
) {
    let filename = '';

    filename = quotation.number + "-" + quotation.title;
    filename = filename.replace(/\//g, "_");
    filename = filename.replace(/\s/g, '');
    filename = filename.replace(/\./g, '');
    
    return filename;
}
