import API from '../../components/Api';
import CTA from '../../components/UI/Generic/CTA';
/**
 * Créer une nouvelle facture à partir d'une autre facture
 * @param {String} invoice_id 
 */
export default async function createFromInvoice(
    invoice_id: String,
    toastManager: Object,
    status
) {
    const data = await API.get(`invoices/${invoice_id}`);

    let newInvoice = data.data;
    delete newInvoice._id;
    delete newInvoice.createdAt;
    delete newInvoice.updatedAt;
    delete newInvoice.sent;
    newInvoice.status = status ? status : 'OPEN';

    let issue_date = new Date().toISOString().split('T')[0];
    let year = issue_date.split('-')[0];
    let month = issue_date.split('-')[1];
    let day = issue_date.split('-')[2];
    newInvoice.issue_date = issue_date;
    newInvoice.title = 'Facture du ' + day + '-' + month + '-' + year;

    const dg = await API.get(`driver-groups/${newInvoice.driver_group}?$select[]=legal_entities`);
    const le = await API.get(`legal_entities/${dg.data.legal_entities}?$select[]=invoice_number`);
    newInvoice.number = year + month + day + '-' + le.data.invoice_number;

    API.post('invoices', newInvoice)
        .then((res) => {
            if (res.data.status !== "CREDIT") {
                toastManager.removeAll();
                toastManager.add('Facture créée', {
                    appearance: 'success',
                    autoDismiss: true
                });

                //Si envoie facture par email 
                if (newInvoice.receiver.email) {
                    CTA({
                        target: {
                            value: {
                                type: 'send-invoice-email',
                                to: [res.data, { _id: newInvoice.driver_group, legal_entities: le.data._id }, {}]
                            }
                        }
                    })
                }
            }
            return res.data;
        }).catch((err) => {
            return err
        })
}
