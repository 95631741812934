import axios from 'axios';
import { apiUrl } from '../const';

const Api = axios.create({
  baseURL: apiUrl
});

Api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export function setAuthorization(Authorization) {
  Api.defaults.headers.common.Authorization = Authorization;
}

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = '/connexion'; // sign-in
  }

  return response;
};

const errorHandler = (error) => {
  if (
    error.response &&
    error.response.status === 401 &&
    JSON.parse(error.config.data).strategy === 'jwt' &&
    !window.location.pathname.includes('/connexion')
  ) {
    window.location = '/connexion'; // sign-in
  }

  if (
    error.response &&
    error.response.status === 502
  ) {
    alert("Problème de connexion au serveur. Veuillez réessayer dans quelques instants");
  }

  return Promise.reject(error);
};

Api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default Api;
