import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const { color } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17.373 7.22a5.845 5.845 0 0 0-2.313-2.4 6.15 6.15 0 0 0-3.197-.818c-.496.01-3.056.182-4.707 2.433C5.674 8.456 5.99 10.67 6.122 11.3a.436.436 0 1 0 .852-.181 5.493 5.493 0 0 1-.084-1.516c.08-.989.405-1.878.966-2.644 1.41-1.922 3.603-2.068 4.028-2.079a5.284 5.284 0 0 1 2.746.705 4.975 4.975 0 0 1 1.97 2.04c.836 1.63.481 3.28.29 3.914a5.39 5.39 0 0 1-.947 1.802l-3.554 4.912v-6.138a2.067 2.067 0 0 0 1.604-2.02c0-1.143-.918-2.07-2.052-2.07a2.061 2.061 0 0 0-2.053 2.07c0 .997.7 1.83 1.63 2.026v7.36c0 .048.008.095.023.139l.027.08a.437.437 0 0 0 .413.301.435.435 0 0 0 .352-.18l4.31-5.955a6.278 6.278 0 0 0 1.081-2.072c.221-.737.631-2.657-.35-4.573zm-6.614 2.874c0-.657.53-1.191 1.181-1.191.652 0 1.182.534 1.182 1.191s-.53 1.192-1.182 1.192a1.188 1.188 0 0 1-1.181-1.192z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string
};

SvgComponent.defaultProps = {
  color: '#0b060d'
};

export default SvgComponent;
