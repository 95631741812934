import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    stroke,
    size,
    strokeWidth,
    strokeOpacity
  } = props;

  return (
    <svg style={{'overflow':'visible'}} width={size} height={size} {...props}>
      <circle
        stroke={stroke}
        fill={color}
        fillRule="nonzero"
        cx={size / 2}
        cy={size / 2}
        r={(size / 2) - 2}
        strokeWidth={strokeWidth}
        strokeOpacity={strokeOpacity}
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeOpacity: PropTypes.number,
};

SvgComponent.defaultProps = {
  stroke: '#0b060d',
  color: '#0b060d',
  size: 16,
  strokeWidth: 2,
  strokeOpacity: 1,
};

export default SvgComponent;
