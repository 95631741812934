import { SET_USER, SET_DRIVER_GROUP } from './types';
/**
 * Déconnexion de l'utilisateur
 */
export function clearUserFromStore() {
  const data = undefined;
  return (dispatch: Function) => {
    localStorage.removeItem('nebulea_remember');
    sessionStorage.removeItem('nebulea_filters');
    dispatch({
      type: SET_USER,
      values: data
    });
    dispatch({
      type: SET_DRIVER_GROUP,
      values: data
    });
  };
}
