import React from 'react';
import { DefaultToastContainer } from 'react-toast-notifications';

const ToastContainer = props => (
  <DefaultToastContainer
    // className="toast-container"
    // css={{ outline: "4px dashed green" }}
    style={{ marginTop: '100px', zIndex: '4' }}
    {...props}
  />
);

export default ToastContainer;
