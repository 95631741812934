import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M10.9942857,8.48 L13.0057143,8.48 L13.0057143,10.9942857 L15.52,10.9942857 L15.52,13.0057143 L13.0057143,13.0057143 L13.0057143,15.52 L10.9942857,15.52 L10.9942857,13.0057143 L8.48,13.0057143 L8.48,10.9942857 L10.9942857,10.9942857 L10.9942857,8.48 Z M12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z M12,19.04 C15.8880846,19.04 19.04,15.8880846 19.04,12 C19.04,8.11191536 15.8880846,4.96 12,4.96 C8.11191536,4.96 4.96,8.11191536 4.96,12 C4.96,15.8880846 8.11191536,19.04 12,19.04 Z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
