import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    size
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <path
        d="M20 11.997c0-1.595-.583-3.098-1.64-4.233C17.3 6.626 15.896 6 14.405 6h-4.2c-1.491 0-2.896.626-3.955 1.764-1.058 1.135-1.64 2.638-1.64 4.233 0 1.198.328 2.345.951 3.322-.236.525-.64 1.196-1.253 1.533a.575.575 0 00-.303.594c.03.251.203.444.445.493.235.04.473.061.706.061a3.79 3.79 0 002.313-.775 5.297 5.297 0 002.736.769h4.2c.153 0 .278-.13.278-.29 0-.16-.125-.291-.278-.291h-4.2c-.92 0-1.82-.27-2.605-.783a.27.27 0 00-.32.018 3.315 3.315 0 01-2.719.722c.812-.445 1.304-1.315 1.573-1.965a.303.303 0 00-.028-.283 5.54 5.54 0 01-.94-3.124c0-1.443.527-2.802 1.483-3.828.953-1.024 2.216-1.588 3.556-1.588h4.2c1.338 0 2.602.564 3.556 1.588.9.966 1.414 2.23 1.47 3.578h-2.407c-.114-.629-.643-1.105-1.28-1.105-.636 0-1.165.476-1.28 1.105h-.825c-.115-.629-.643-1.105-1.28-1.105-.636 0-1.165.476-1.28 1.105h-.825c-.115-.629-.643-1.105-1.28-1.105-.72 0-1.302.609-1.302 1.36 0 .75.583 1.359 1.302 1.359.612 0 1.123-.44 1.264-1.033h.857c.14.592.653 1.033 1.264 1.033.612 0 1.123-.44 1.264-1.033h.857c.14.592.653 1.033 1.264 1.033.612 0 1.123-.44 1.264-1.033h2.714c.153 0 .278-.13.278-.29 0-.008-.003-.015-.004-.021l.003-.021zm-11.024.788c-.414 0-.75-.351-.75-.783 0-.432.336-.783.75-.783.413 0 .75.35.75.783 0 .432-.337.783-.75.783zm3.384 0c-.414 0-.75-.351-.75-.783 0-.432.336-.783.75-.783.413 0 .75.35.75.783 0 .432-.337.783-.75.783zm3.384 0c-.413 0-.75-.351-.75-.783 0-.432.336-.783.75-.783s.75.35.75.783c0 .432-.336.783-.75.783z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
  size: 24,
};

export default SvgComponent;
