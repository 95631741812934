import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M8.665 4A4.672 4.672 0 0 0 4 8.668v6.667A4.672 4.672 0 0 0 8.668 20h6.667A4.672 4.672 0 0 0 20 15.332V8.665A4.672 4.672 0 0 0 15.332 4H8.665zm8.002 2.667a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zm0 1.333a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
