import { getAsyncDirections } from '../../components/Map/mapbox';
import API from '../../components/Api';

export default async function estimation(selectedDriverGroup, values, stops, toastManager) {
  let res = {};

  /* if (values.duration && values.distance) {
    res = await calculateEstimation(values.distance, values.duration, selectedDriverGroup, values, toastManager);
    return res;
  } else  */if (
    values.arrival_address &&
    values.arrival_address !== '' &&
    values.departure_address &&
    values.departure_address !== ''
  ) {
    const departurePoint = {
      latitude: values.departure_latitude,
      longitude: values.departure_longitude
    };
    const arrivalPoint = {
      latitude: values.arrival_latitude,
      longitude: values.arrival_longitude
    };

    const directions = await getAsyncDirections(departurePoint, arrivalPoint, values.departure_date ? values.departure_date : null, stops, values.service);

    res = await calculateEstimation(directions.distance, directions.duration, selectedDriverGroup && selectedDriverGroup._id ? selectedDriverGroup : { _id: values.driver_group }, values, toastManager, stops);

    return { ...directions, ...res };
  } else {
    return false;
  }
}

async function calculateEstimation(distance, duration, selectedDriverGroup, values, toastManager, stops) {
  let url = 'estimation?';
  if (values.departure_date) {
    url += 'departure_date=' +
      (typeof values.departure_date === 'string' ? values.departure_date : values.departure_date.toISOString());
  }

  url += `&distance=${distance}`;
  url += `&driver_group=${selectedDriverGroup._id ? selectedDriverGroup._id : values.driver_group._id}`;
  url += `&duration=${duration}`;
  if (values.business_group) url += `&business_group=${values.business_group}`;
  url += `&service=${values.service}`;
  url += `&departure_latitude=${values.departure_latitude}`;
  url += `&departure_longitude=${values.departure_longitude}`;
  url += `&arrival_latitude=${values.arrival_latitude}`;
  url += `&arrival_longitude=${values.arrival_longitude}`;
  url += `&stops=${stops && stops.length > 0}`;

  let resQuotation = null;
  try {
    resQuotation = await API.get(url);
    resQuotation.data.price_sc = resQuotation.data.price_subcontractor;
  } catch (error) {
    if (toastManager) {
      toastManager.add(error, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  return resQuotation.data;
}
