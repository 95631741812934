// @flow

import React from 'react';
import { MDBInput, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import { CancelToken } from 'axios';
import uuid from 'uuid/v4';

type Props = {
  address?: string,
  placeHolder?: string,
  onChange: Function,
  className?: string,
  classNameContainer?: string,
  proximity?: Object
};
type State = {
  activeSuggestion: ?boolean,
  address: ?string,
  placeHolder: ?string,
  matching: Array<Object>,
  isOpen: ?boolean,
  fetching: ?Object
};

/**
 * Classe permettant de faire l'autocomplète sur une adresse
 */
class GeoCoder extends React.Component<Props, State> {
  static defaultProps = {
    address: '',
    placeHolder: '',
    className: '',
    classNameContainer: '',
    proximity: null
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      address: props.address,
      placeHolder: props.placeHolder,
      matching: [],
      isOpen: false,
      fetching: null
    };

    this.sessionToken = uuid();
  }

  /**
   * Le composant reçoit une nouvelle valeur
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const { address, placeHolder } = props;

    const newObject = {};
    let haveChanges = false;
    if (address && address !== state.address) {
      newObject.address = address;
      haveChanges = true;
    }
    if (placeHolder && placeHolder !== state.placeHolder) {
      newObject.placeholder = placeHolder;
      haveChanges = true;
    }

    if (haveChanges) {
      return newObject;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.setState({
        address: this.props.address
      });
    }
  }

  /**
   * Méthode permettant de mettre à jour l'adresse saisie
   */
  handleInputChange = (event: Object) => {
    const { onChange } = this.props;
    const { target } = event;
    const { value } = target;

    onChange({ place_name: '' });

    this.setState(
      {
        address: value
      },
      () => {
        // Plus de 3 caractères saisis, on appelle la prédiction
        if (value.length > 4 && value.length % 2 === 1) {
          this.getGeoCoding();
        } else if (value.length > 4) {
          this.setState({
            activeSuggestion: 0,
            isOpen: true
          });
        } else {
          this.setState({
            activeSuggestion: 0,
            isOpen: false
          });
        }
      }
    );
  };

  /**
   * Méthode permettant de contacter Mapbox
   */
  getGeoCoding = () => {
    const { proximity } = this.props;
    const { address, fetching } = this.state;

    // Il y a déjà une requête en cours, on l'annule
    if (fetching) {
      fetching.cancel('Operation canceled by the user.');
    }
    const newSource = CancelToken.source();
    // Il y a bien une adresse de saisie
    if (address) {
      let request = {
        input: address,
        sessiontoken: this.sessionToken
      };

      // console.log(proximity);
      if (proximity) {
        request.location = new google.maps.LatLng({
          lat: proximity.latitude,
          lng: proximity.longitude
        });
        request.radius = 100000;
      } else {
        //Centre de la France
        request.location = new google.maps.LatLng({
          lat: 46.227638,
          lng: 2.213749
        });
        request.radius = 1000000;
      }

      /*global google*/
      this.setState(
        {
          fetching: newSource
        },
        () => {
          try {
            let service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions(request, (predictions, status) => {
              /*global google*/
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                // console.log(status);
                return;
              }
              this.setState({
                matching: predictions,
                isOpen: true,
                fetching: false,
                activeSuggestion: 0
              });
              return true;
            });
          } catch (err) {}
        }
      );
    }
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, name, matching, isOpen } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13 && matching[activeSuggestion]) {
      this.selectAddress(matching[activeSuggestion]);
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === matching.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  /**
   * L'utilisateur sélectionne une adresse de la liste
   */
  selectAddress = (item: Object) => {
    const { onChange } = this.props;

    new google.maps.Geocoder().geocode({ placeId: item.place_id }, (results, status) => {
      if (status === 'OK') {
        this.setState(
          {
            address: item.description,
            isOpen: false
          },
          () => {
            //Mapbox format
            const place = results[0];
            item.place_name = item.description;
            item.center = [place.geometry.location.lng(), place.geometry.location.lat()];
            // On envoit l'adresse sélectionnée à l'instance parente
            onChange(item);
          }
        );
      } else {
        console.log(status);
      }
    });
  };

  /**
   * Méthode permettant d'afficher la liste des résultats
   */
  renderList() {
    const { activeSuggestion, matching, isOpen } = this.state;

    if (!isOpen || matching.length === 0) {
      return null;
    }

    return (
      <div className="geocoder--results">
        <MDBListGroup>
          {matching.map((address, index) => (
            <MDBListGroupItem
              onClick={() => this.selectAddress(address)}
              key={address.place_id}
              active={index === activeSuggestion ? true : false}
            >
              {address.description}
            </MDBListGroupItem>
          ))}
        </MDBListGroup>
      </div>
    );
  }

  /**
   * Méthode permettant le rendu de l'instance
   */
  render() {
    const { className, classNameContainer, hideLabel } = this.props;
    const { address, placeHolder } = this.state;
    return (
      <div className={classNameContainer}>
        <MDBInput
          type="text"
          label={hideLabel ? '' : placeHolder}
          value={address || ''}
          name="address"
          className={className}
          hint={hideLabel ? placeHolder : ''}
          onChange={this.handleInputChange}
          onKeyDown={this.onKeyDown}
          autoComplete="disabled"
        />
        {this.renderList()}
      </div>
    );
  }
}

export default GeoCoder;
