import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M14.571 8v3.636h-4.294a1.055 1.055 0 00-.991-.727c-.584 0-1.058.489-1.058 1.091s.474 1.09 1.058 1.09c.46 0 .845-.303.99-.726h4.295V16H4V8h10.571zm0 4.364v-.728h4.075l-2.214-2.284a.37.37 0 01-.075-.426.348.348 0 01.373-.199.35.35 0 01.209.103l2.819 2.909L20 12l-.242.261-2.82 2.91a.35.35 0 01-.506 0 .377.377 0 010-.523l2.214-2.284H14.57z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
