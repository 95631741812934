// @flow

import React from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { withToastManager } from 'react-toast-notifications';
import { injectIntl } from 'react-intl';

// Redux ------------------------------------
import { connect } from 'react-redux';
import Api from '../../../Api';
import { withMobileContext } from '../../../../modules/MobileContext';

import MoreInformations from './MoreInformations';
import Header from './Header';
import User from './User';
import AddressFrame from './AddressFrame';
import PriceAndCTA from './PriceAndCTA';

import { getGeoCoding, getDirections } from '../../../Map/mapbox';
import CTA from '../../Generic/CTA';
import { setUser } from '../../../../actions/users';
import { removeError } from '../../../../actions/errors';
import estimation from '../../../../actions/rides/estimation';
import { PrepaymentRequired } from '../../../../const';

type Props = {
  intl: Object,
  user: Object,
  dg: Object,
  bg: Object,
  history: Object,
  match: {
    params: {
      driverGroupId: string,
      service?: string,
      departure?: string,
      arrival?: string
    }
  },
  toastManager: Object,
  sm: boolean,
  md: boolean
};

type State = {
  user: {
    _id: string | null,
    name: string,
    first_name: String,
    email: string,
    phone: string
  },
  booking: {
    departure_address: string,
    arrival_address: string,
    nb_passenger: number,
    service: string,
    flight_train_number: string,
    code_promo: string,
    comment_public: string,
    departure_latitude: number,
    departure_longitude: number,
    arrival_latitude: number,
    arrival_longitude: number,
    departure_date: moment | null,
    arrival_date: moment | null,
    price_client: number,
    high_estimate: number,
    low_estimate: number
  },
  activeTab: string,
  derived: boolean,
  moreInformationsOpen: boolean,
  estimatePrice: string,
  isFetching: boolean,
  focusedStartDate: boolean,
  focusedArrivalDate: boolean,
  hideContent: boolean
};

class Booking extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { match, sm, md } = this.props;

    let departure_date = new Date();
    departure_date.setMinutes(new Date().getMinutes() + 10);

    this.state = {
      user: {
        _id: null,
        name: '',
        first_name: '',
        email: '',
        phone: '+33'
      },
      errors: {
        name: false,
        first_name: false,
        email: false,
        phone: false,
        flight_train_number: false
      },
      booking: {
        departure_address: match.params.departure || '',
        arrival_address: match.params.arrival || '',
        nb_passenger: 1,
        service: match.params.service || 'STANDARD',
        flight_train_number: null,
        flight_train_number_return: null,
        code_promo: '',
        comment_public: '',
        departure_latitude: 0,
        departure_longitude: 0,
        arrival_latitude: 0,
        arrival_longitude: 0,
        departure_date: departure_date.toISOString(),
        return_date: null,
        arrival_date: null,
        price_client: 0,
        price_sc: 0,
        return_price_client: 0,
        return_price_sc: 0,
        high_estimate: null,
        low_estimate: null,
        notify_passenger: true
      },
      activeTab: sm || md ? 'startAt' : 'startAt',
      derived: false,
      estimatePrice: '',
      isFetching: false,
      focusedStartDate: false,
      focusedArrivalDate: false,
      hideContent: true,
      mandatoryFlightTrain: false,
      showConfirmationMessage: false,
      invited: false
    };

    this.setPreQuotation();
  }

  componentDidMount() {
    const { toastManager, dg, bg } = this.props;
    const queryParams = {};

    if (dg && dg.services) {
      let availablesServices = [];
      Object.keys(dg.services).forEach((key) => {
        if (dg.services[key].available === true) {
          availablesServices.push(key);
        }
      });

      if (!availablesServices.includes('standard')) {
        this.handleBookingInputChange({ target: { name: 'service', value: availablesServices[0].toUpperCase() } });
      }
    }

    if (bg && bg.business_units.length > 0 && bg.business_units[0].invited) {
      this.setState({ invited: true })
    }

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      queryParams[key] = value;
    });

    if (queryParams.origin) {
      Api.get(`rides/${queryParams.origin}`)
        .then((resRide) => {
          const { data } = resRide;

          getGeoCoding(
            queryParams.action && queryParams.action === 'bookback' ? data.arrival_address : data.departure_address,
            null,
            (errDeparture, departure) => {
              if (!errDeparture) {
                const departurePoint = {
                  latitude: departure.center[1],
                  longitude: departure.center[0]
                };

                getGeoCoding(
                  queryParams.action && queryParams.action === 'bookback'
                    ? data.departure_address
                    : data.arrival_address,
                  null,
                  (errArrival, arrival) => {
                    if (!errArrival) {
                      const arrivalPoint = {
                        latitude: arrival.center[1],
                        longitude: arrival.center[0]
                      };

                      const booking = {
                        business_group: data.business_group ? data.business_group._id : null,
                        business_unit: data.business_unit ? data.business_unit._id : null,
                        departure_address:
                          queryParams.action && queryParams.action === 'bookback'
                            ? data.arrival_address
                            : data.departure_address,
                        arrival_address:
                          queryParams.action && queryParams.action === 'bookback'
                            ? data.departure_address
                            : data.arrival_address,
                        nb_passenger: data.nb_passenger,
                        service: data.service,
                        flight_train_number: data.flight_train_number,
                        code_promo: data.code_promo,
                        comment_public: data.comment_public,
                        departure_latitude: departurePoint.latitude,
                        departure_longitude: departurePoint.longitude,
                        arrival_latitude: arrivalPoint.latitude,
                        arrival_longitude: arrivalPoint.longitude,
                        departure_date: moment(new Date()).toISOString(),
                        arrival_date: moment(data.arrival_date).toISOString(),
                        price_client: queryParams.action && queryParams.action === 'bookback' ? data.price_client : 0,
                        high_estimate: data.high_estimate,
                        low_estimate: data.low_estimate,
                        notify_passenger: data.notify_passenger
                      };

                      const user = data.passenger;
                      const estimatePrice = `${data.low_estimate} - ${data.high_estimate} ${dg.currency}`;

                      this.setState({
                        booking,
                        user,
                        estimatePrice
                      });
                    }
                  }
                );
              }
            }
          );
        })
        .catch((err) => {
          toastManager.add(err, { appearance: 'error', autoDismiss: true });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { dg, bg } = this.props;

    if (dg !== prevProps.dg) {
      if (dg && dg.services) {
        let availablesServices = [];
        Object.keys(dg.services).forEach((key) => {
          if (dg.services[key].available === true) {
            availablesServices.push(key);
          }
        });

        if (!availablesServices.includes('standard')) {
          this.handleBookingInputChange({ target: { name: 'service', value: availablesServices[0].toUpperCase() } });
        }
      }
    }

    if (!prevProps.bg && bg && bg.business_units.length > 0 && bg.business_units[0].invited) {
      this.setState({ invited: true })
    }


  }
  /**
   * Méthode appellée lorsque l'instance reçoit une nouvelle prop
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const { user } = props;

    if (user && !state.user.name && !state.derived) {
      return {
        user,
        derived: true
      };
    }

    return null;
  }

  setActiveTab = (activeTab: string) => {
    this.setState({
      activeTab,
      hideContent: false
    });
  };

  handleInputChange = (event: Object, path: string) => {
    const { target } = event;
    const { type, checked, value, name } = target;
    const { dg } = this.props;

    if(name === 'departure_date' && ((dg.dispatching.minimum_before_booking && value.isSameOrBefore(moment().add(dg.dispatching.minimum_before_booking, 'minutes'))) || value.isSameOrBefore(moment()))){
      window.alert(`Votre départ est trop proche, réservez au moins ${dg.dispatching.minimum_before_booking || 0} minutes à l'avance`);
      return;
    } 

    this.setState(
      (prevState) => ({
        ...prevState,
        [path]: {
          ...prevState[path],
          [name]: type === 'checkbox' ? checked : value
        }
      }),
      () => {
        if (['arrival_date', 'service'].indexOf(name) !== -1) {
          this.quotation();
        }
      }
    );
  };

  handleUserInputChange = (event: Object) => {
    this.handleInputChange(event, 'user');
  };

  handleBookingInputChange = (event: Object) => {
    this.handleInputChange(event, 'booking');
  };

  getDirections = (callback: Function) => {
    const { toastManager, intl } = this.props;
    const { booking } = this.state;

    const departurePoint = {
      latitude: booking.departure_latitude,
      longitude: booking.departure_longitude
    };
    const arrivalPoint = {
      latitude: booking.arrival_latitude,
      longitude: booking.arrival_longitude
    };

    getDirections(departurePoint, arrivalPoint, null, [], booking.service, (err, res) => {
      if (err) {
        this.setState({ isFetching: false });
        toastManager.add(intl.formatMessage({ id: 'errors.mapbox' }), {
          appearance: 'error',
          autoDismiss: true
        });
        callback(err);
        return false;
      }

      callback(null, res);
      return true;
    });
  };

  setPreQuotation = () => {
    const { match } = this.props;

    if (
      match.params &&
      match.params.departure &&
      match.params.departure !== '' &&
      match.params.arrival &&
      match.params.arrival !== ''
    ) {
      getGeoCoding(match.params.departure, null, (errDeparture, departure) => {
        if (!errDeparture) {
          getGeoCoding(match.params.arrival, null, (errArrival, arrival) => {
            if (!errArrival) {
              this.setState(
                (prevState) => ({
                  booking: {
                    ...prevState.booking,
                    departure_latitude: departure.center[1],
                    departure_longitude: departure.center[0],
                    arrival_latitude: arrival.center[1],
                    arrival_longitude: arrival.center[0]
                  }
                }),
                () => {
                  this.quotation();
                }
              );
            }
          });
        }
      });
    }
  };

  quotation = async (type) => {
    const { toastManager, match, dg, bg } = this.props;
    const { booking, activeTab } = this.state;

    if (type === 'return_date') {
      const returnValues = {
        ...booking,
        departure_date: booking.return_date
      }
      const result = await estimation(dg, returnValues, [], toastManager);

      this.setState((prevState) => ({
        ...prevState,
        booking: {
          ...prevState.booking,
          return_price_client: result.price_client,
          return_price_sc: result.price_sc,
          return_low_estimate: result.low_estimate,
          return_high_estimate: result.high_estimate
        },
        return_estimatePrice: `${result.low_estimate} - ${result.high_estimate} €`
      }));
    } else {
      if (booking.arrival_address && booking.departure_address) {
        this.getDirections((err, res) => {
          if (!err) {
            let url = 'estimation?departure_date=';
            switch (activeTab) {
              case 'now':
                url += new Date().toISOString();
                break;
              case 'startAt':
                if (booking.departure_date) {
                  url += moment(booking.departure_date).toISOString();
                }

                break;
              case 'arrivalAt':
                if (booking.arrival_date) {
                  let date = new moment(booking.arrival_date);
                  url += date
                    .subtract(res.duration, 'seconds')
                    .subtract(15, 'minutes')
                    .toISOString();
                }
                break;
              default:
                break;
            }

            url += `&distance=${res.distance}`;
            url += `&driver_group=${match.params.driverGroupId}`;
            url += `&duration=${res.duration}`;
            url += `&service=${booking.service}`;
            url += `&departure_latitude=${booking.departure_latitude}`;
            url += `&departure_longitude=${booking.departure_longitude}`;
            url += `&arrival_latitude=${booking.arrival_latitude}`;
            url += `&arrival_longitude=${booking.arrival_longitude}`;
            url += `&stops=false`;

            if(bg && bg._id){
              url+= `&business_group=${bg._id}`
            }

            Api.get(url)
              .then((resQuotation) => {
                this.setState((prevState) => ({
                  ...prevState,
                  booking: {
                    ...prevState.booking,
                    duration: res.duration,
                    distance: res.distance,
                    waypoints: res.waypoints,
                    price_sc: resQuotation.data.price_subcontractor,
                    catalogue: resQuotation.data.catalogue,
                    price_client: resQuotation.data.price_client,
                    low_estimate: resQuotation.data.low_estimate,
                    high_estimate: resQuotation.data.high_estimate
                  },
                  estimatePrice: resQuotation.data.price_client
                    ? resQuotation.data.price_client + ' €'
                    : `${resQuotation.data.low_estimate} - ${resQuotation.data.high_estimate} €`
                }));
              })
              .catch((errQuotation) => {
                toastManager.add(errQuotation.message, {
                  appearance: 'error',
                  autoDismiss: true
                });
              });
          }
        });
      }
    }
  };

  createRide = (status) => {
    const { toastManager, dg, bg, intl, history } = this.props;
    const { booking, activeTab, user, errors, mandatoryFlightTrain, showConfirmationMessage, invited } = this.state;

    let error = false;
    errors.name = false;
    errors.first_name = false;
    errors.email = false;
    errors.phone = false;

    if (!user._id) {
      if (!user.name) {
        error = true;
        errors.name = true;
      }
      if (!user.first_name) {
        error = true;
        errors.first_name = true;
      }
      if (!user.email) {
        error = true;
        errors.email = true;
      }
      if (!user.phone || user.phone.length < 8) {
        error = true;
        errors.phone = true;
      }
    }

    if (mandatoryFlightTrain && !booking.flight_train_number) {
      error = true;
      errors.flight_train_number = true;
    }

    if (error) {
      this.setState({ errors: errors });
      return;
    } else if (dg.online_payment_id && !showConfirmationMessage && !invited && dg.mandatoryPayment) {
      this.setState({ showConfirmationMessage: true });
      return;
    } else {
      this.setState(
        {
          isFetching: true
        },
        () => {
          const ride = {
            driver_group: dg._id,
            departure_address: booking.departure_address,
            arrival_address: booking.arrival_address,
            arrival_latitude: booking.arrival_latitude,
            arrival_longitude: booking.arrival_longitude,
            nb_passenger: booking.nb_passenger,
            service: booking.service,
            flight_train_number: booking.flight_train_number,
            code_promo: booking.code_promo,
            comment_public: booking.comment_public,
            departure_latitude: booking.departure_latitude,
            departure_longitude: booking.departure_longitude,
            waypoints: booking.waypoints,
            duration: booking.duration,
            distance: booking.distance,
            passenger: user && user._id ? user._id : null,
            price_client: booking.price_client,
            high_estimate: booking.high_estimate,
            low_estimate: booking.low_estimate,
            notify_passenger: booking.notify_passenger
          };


          switch (activeTab) {
            case 'now':
              ride.departure_date = new Date().toISOString();
              break;
            case 'startAt':
              if (booking.departure_date) {
                ride.departure_date = booking.departure_date;
              }
              break;
            case 'arrivalAt':
              if (booking.arrival_date) {
                let date = new moment(booking.arrival_date);
                ride.departure_date = date.subtract(booking.duration, 'seconds').subtract(15, 'minutes');
              }
              break;
            default:
              break;
          }

          //Course pour les entreprises réservées depuis le profil du DG
          if (bg) {
            ride.business_group = bg._id;
            if (bg.business_units.length > 0) {
              ride.business_unit = bg.business_units[0]._id;
              ride.invited = bg.business_units[0].invited;
            }
          }
          if ((!booking.invited || !ride.invited) && ((dg.online_payment_id && dg.mandatoryPayment) || status === "QUOTE")) {
            ride.status = "QUOTE"
          } else {
            ride.status = "PROCESSING"
          }

          if (user && user._id) {
            // Création d'une course
            Api.post('rides', ride)
              .then((resRide) => {
                CTA({
                  target: {
                    value: {
                      type: 'online-booking-email',
                      to: [resRide.data, !booking.invited && dg.online_payment_id && dg.mandatoryPayment ]
                    }
                  }
                })

                if (booking.return_date) {
                  const valuesReturn = {
                    ...resRide.data,
                    departure_date: booking.return_date,
                    departure_address: booking.arrival_address,
                    departure_latitude: booking.arrival_latitude,
                    departure_longitude: booking.arrival_longitude,
                    arrival_address: booking.departure_address,
                    arrival_latitude: booking.departure_latitude,
                    arrival_longitude: booking.departure_longitude,
                    flight_train_number: booking.flight_train_number_return,
                    price_client: booking.return_price_client,
                    price_sc: booking.return_price_sc,
                    low_estimate: booking.return_low_estimate,
                    high_estimate: booking.return_high_estimate
                  };
                  delete valuesReturn._id;
                  delete valuesReturn.catalogue;

                  Api.post('rides', valuesReturn).then((resReturnRide) => {
                    CTA({
                      target: {
                        value: {
                          type: 'online-booking-email',
                          to: [resReturnRide.data, !booking.invited && dg.online_payment_id && dg.mandatoryPayment ]
                        }
                      }
                    })
                  })
                }
                window.location = `https://nebulea.eu/${intl.formatMessage({ id: 'routes.passenger' })}/${resRide.data._id}`;
                //history.push(`/${intl.formatMessage({ id: 'routes.passenger' })}/${resRide.data._id}`);
              })
              .catch((errRide) => {
                toastManager.add(errRide, {
                  appearance: 'error',
                  autoDismiss: true
                });
              });
          } else {
            // Création du user
            let u = user;
            delete u._id;
            if (u.phone && u.phone.length <= 3) {
              u.phone = null;
            }

            Api.post('users', u)
              .then((resUser) => {
                if (resUser.data._id) ride.passenger = resUser.data._id;
                else if (resUser.data[0]._id) ride.passenger = resUser.data[0]._id;
                // Création d'une course
                Api.post('rides', ride)
                  .then((resRide) => {
                    CTA({
                      target: {
                        value: {
                          type: 'online-booking-email',
                          to: [resRide.data, !booking.invited && dg.online_payment_id && PrepaymentRequired.includes(dg._id) ]
                        }
                      }
                    })
                    if (booking.return_date) {
                      const valuesReturn = {
                        ...resRide.data,
                        departure_date: booking.return_date,
                        departure_address: booking.arrival_address,
                        departure_latitude: booking.arrival_latitude,
                        departure_longitude: booking.arrival_longitude,
                        arrival_address: booking.departure_address,
                        arrival_latitude: booking.departure_latitude,
                        arrival_longitude: booking.departure_longitude,
                        flight_train_number: booking.flight_train_number_return,
                        price_client: booking.return_price_client,
                        price_sc: booking.return_price_sc,
                        low_estimate: booking.return_low_estimate,
                        high_estimate: booking.return_high_estimate
                      };
                      delete valuesReturn._id;
                      delete valuesReturn.catalogue;

                      Api.post('rides', valuesReturn).then((resReturnRide) => {
                        CTA({
                          target: {
                            value: {
                              type: 'online-booking-email',
                              to: [resReturnRide.data, !booking.invited && dg.online_payment_id && PrepaymentRequired.includes(dg._id) ]
                            }
                          }
                        })
                      })
                    }
                    window.location = `https://nebulea.eu/${intl.formatMessage({ id: 'routes.passenger' })}/${resRide.data._id}`;
                    //history.push(`/${intl.formatMessage({ id: 'routes.passenger' })}/${resRide.data._id}`);
                    this.setState({ isFetching: false });
                  })
                  .catch((errRide) => {
                    this.setState({ isFetching: false });
                    toastManager.add(
                      intl.formatMessage({
                        id: 'driver-group.booking.form.error.ride'
                      }),
                      { appearance: 'error', autoDismiss: true }
                    );
                  });
              })
              .catch((errUser) => {
                this.setState({ isFetching: false });
                toastManager.add(
                  intl.formatMessage({
                    id: 'driver-group.booking.form.error.passenger'
                  }),
                  { appearance: 'error', autoDismiss: true }
                );
              });
          }

          return true;
        });
    }
  };

  setPoint = (address: Object, type: string) => {
    if (address.center) {
      this.setState(
        (prevState) => ({
          booking: {
            ...prevState.booking,
            [type]: address.place_name,
            [`${type.split('_')[0]}_latitude`]: address.center[1],
            [`${type.split('_')[0]}_longitude`]: address.center[0]
          }
        }),
        () => {
          this.quotation();
        }
      );
    } else {
      this.setState((prevState) => ({
        booking: {
          ...prevState.booking,
          [type]: address.place_name
        }
      }));
    }

    //Check if departure is from Train Station or Airport
    if (type === 'departure_address') {
      this.setState({
        mandatoryFlightTrain: (address.place_name.includes('are ') || address.place_name.includes('irport') || address.place_name.includes('roport'))
      })
    }
  };

  submitUserForm = () => {
    const { sm, md, lg, history, logUser } = this.props;
    const { user } = this.state;

    const data = {
      password: user.password,
      remember: true,
      strategy: 'local',
      email: user.email.trim()
    };

    const mobileContext = { sm, md, lg };
    logUser(data, history, mobileContext);
  }

  setService = (service: string) => {
    this.setState(
      (prevState) => ({
        booking: {
          ...prevState.booking,
          service
        }
      }),
      () => {
        this.quotation();
      }
    );
  };

  increasePassenger = (increase: ?boolean) => {
    const { booking } = this.state;
    let res = 1;

    switch (booking.service) {
      case "STANDARD":
      case "GREEN":
      case "VIP":
      case "MEDICAL_TAXI":
        res = Math.min(Number(booking.nb_passenger) + (increase ? 1 : Number(booking.nb_passenger) > 1 ? -1 : 0), 4)
        break;
      case 'VAN':
      case 'VAN_VIP':
        res = Math.min(Number(booking.nb_passenger) + (increase ? 1 : Number(booking.nb_passenger) > 1 ? -1 : 0), 7)
        break;
      case 'MOTO_TAXI':
        res = Math.min(Number(booking.nb_passenger) + (increase ? 1 : Number(booking.nb_passenger) > 1 ? -1 : 0), 1)
        break;
      default:
        res = Math.min(Number(booking.nb_passenger) + (increase ? 1 : Number(booking.nb_passenger) > 1 ? -1 : 0), 4)
    }

    this.setState((prevState) => ({
      booking: {
        ...prevState.booking,
        nb_passenger: res
      }
    }));
  };

  render() {
    const { intl, dg, sm, md } = this.props;
    const { hideContent, booking, activeTab, isFetching, estimatePrice, price_client, errors, user, mandatoryFlightTrain, showConfirmationMessage, invited } = this.state;

    return (
      <div className="Booking">
        <Header intl={intl} activeTab={activeTab} setActiveTab={this.setActiveTab} support_phone={dg.support_phone} />
        <div className={`Content ${(sm || md) && hideContent ? 'hidden' : ''}`}>
          <AddressFrame
            intl={intl}
            dg={dg}
            booking={booking}
            activeTab={activeTab}
            setPoint={this.setPoint}
            setService={this.setService}
            handleBookingInputChange={this.handleBookingInputChange}
            increasePassenger={this.increasePassenger}
            vertical={true}
            estimatePrice={estimatePrice}
            price_client={price_client}
            quotation={this.quotation}
            invited={invited}
          />
          <MoreInformations
            intl={intl}
            booking={booking}
            handleBookingInputChange={this.handleBookingInputChange}
            errors={errors}
            mandatoryFlightTrain={mandatoryFlightTrain}
          />
          <User
            {...this.props}
            booking={booking}
            dg={dg}
            user={user}
            errors={errors}
            handleUserInputChange={this.handleUserInputChange}
            submitForm={this.submitUserForm}
          />
          <PriceAndCTA
            intl={intl}
            dg={dg}
            booking={booking}
            cgv={dg.standard_form_contract && dg.standard_form_contract.includes('//') ? dg.standard_form_contract : null}
            isFetching={isFetching}
            createRide={this.createRide}
            showConfirmationMessage={showConfirmationMessage}
            invited={invited}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.users.infos,
  isFetching: state.isFetching.isFetching,
  errors: state.errors
});

export default connect(mapStateToProps, {
  logUser: setUser,
  removeErrorLogin: removeError
})(withMobileContext(injectIntl(withToastManager(Booking))));
