// /**
//  * Sépare la rue et la ville pour les Travelcards
//  * @param {string} address
//  */
// export function convertAddressToMultiLines(address: string): Object {
//   const split = address.split(',');
//   let firstLine = '';
//   let city='';
//   if (split.length === 1) {
//     firstLine = address;
//     city=split[split.length - 2]
//   } else if(split.length === 3) {
//     firstLine=split[0];
//     city = split[2];
//   }else {
//     for (let i = 0; i < split.length - 2; i += 1) {
//       firstLine += (i > 0 ? ',' : '') + split[i];
//     }
//     city=split[split.length - 2];
//   }

//   return {
//     firstLine,
//     city
//   };
// }

// /**
//  * Récupère une partie d'une adresse
//  * @param {string} address
//  * @param {string} part
//  */
// export function getPartOfAddress(address: string, part: string) {
//   if (address) {
//     return convertAddressToMultiLines(address)[part];
//   }
//   return null;
// }

/**
 * Raccourci l'adresse pour Driver > Manager > Rides > List
 * @param {string} address
 */
export function shortenAddress(address: string) {
  const split = address.split(',');
  let firstLine = '';

  if (split.length === 1) {
    firstLine = address;
  } else {
    firstLine = split[0] + ", " + split[split.length - 2];
  }

  return firstLine;
}
/**
 * Récupère une addresse raccourcie
 * @param {string} address 
 */
export function getShorterAddress(address: string) {
  if (address) {
    return shortenAddress(address);
  }
  return null;
}

/**
 * Sépare la rue et la ville pour Driver > Operator > Next Rides
 * @param {string} address
 */
export function convertAddressToMultiLinesShort(address: string): Object {
  const split = address.split(',');
  let firstLine = '';
  let city = '';

  if (split.length === 1) {
    firstLine = address;
  } else if (split.length === 2 || split.length === 3) {
    firstLine = split[0];
  } else {
    for (let i = 0; i < split.length - 2; i += 1) {
      firstLine += (i > 0 ? ',' : '') + split[i];
    }
  }

  if (split.length < 3) {
    city = split[split.length - 1];
  } else {
    city = split[split.length - 2];

    if (split[split.length - 1].trim() !== "France") {
      city += ", " + split[split.length - 1]
    }
  }

  return {
    firstLine,
    city: city
  };
}

/**
 * Récupère une partie de l'adresse raccourcie
 * @param {string} address
 * @param {string} part
 */
export function getPartOfAddressShort(address: string, part: string) {
  if (address) {
    return convertAddressToMultiLinesShort(address)[part];
  }
  return null;
}
