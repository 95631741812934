import API from '../../components/Api';

/**
 * Supprime un chauffeur
 * @param {Object} history historique
 * @param {Object} selectedDriverGroup DG sélectionné
 */
export default function deleteDriver(driver_id: String, selectedDriverGroup: Object, updateDG: Function, toastManager: Object) {
  API.get(`users/${driver_id}`)
    .then((res) => {
      const driver = {
        driver_groups: res.data.driver_groups.filter((dg) => dg._id !== selectedDriverGroup._id)
      };
      API.patch(`users/${driver_id}`, driver)
        .then(async () => {
          toastManager.add('Utilisateur retiré de la liste', {
            appearance: 'success',
            autoDismiss: true
          });

          const resDG = await API.get(`driver-groups/${selectedDriverGroup._id}`);
          updateDG(resDG.data);
        })
        .catch((err) => {
          console.log(err);
          // toastManager.add(err, { appearance: 'error', autoDismiss: true });
        });
    })
    .catch((err) => {
      console.log(err);
      // toastManager.add(err, { appearance: 'error', autoDismiss: true });
    });

  return selectedDriverGroup;
}
