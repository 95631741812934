import React from 'react';
import { MDBBtn, MDBInput, MDBRow, MDBCol, MDBNavLink } from 'mdbreact';

// Traduction
import { injectIntl } from 'react-intl';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import ErrorMessage from '../../Generic/ErrorMessage';
import { BookingPlatformAllowed } from '../../../../const';

type Props = {
  intl: Object,
  history: Object,
  user: Object,
  handleUserInputChange: Function
};

class User extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isConnect: true
    }
  }

  render() {
    const { intl, user, errors, handleUserInputChange, dg, submitForm } = this.props;
    const { isConnect } = this.state;

    if (user._id) {
      return <p className='p-3 m-0 w-100 text-center'>Vous êtes connecté(e) en tant que <b>{user.first_name} {user.name}</b>.</p>
    } else {
      return (
        <div className="User">
          <h4>Votre compte</h4>

          {/* PAID LOGIN or NOT */}
          {dg && BookingPlatformAllowed.includes(dg._id) && isConnect ?
            <>
              <MDBRow>
                <MDBCol size="12">
                  <MDBInput
                    type="email"
                    name="email"
                    autoComplete="email"
                    label={intl.formatMessage({ id: 'driver-group.booking.form.email' })}
                    value={user.email}
                    onChange={handleUserInputChange}
                  />
                </MDBCol>
                <ErrorMessage
                  content={intl.formatMessage({
                    id: 'driver-group.booking.error.name'
                  })}
                  isVisible={errors.name}
                />
                <MDBCol size="12">
                  <MDBInput
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    label={intl.formatMessage({ id: 'signin.password' })}
                    value={user.password}
                    onChange={handleUserInputChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="largeBottomSpacing">
                <MDBNavLink
                  to={`/${intl.formatMessage({
                    id: 'routes.lost-password'
                  })}`}
                  className="primary"
                >
                  {intl.formatMessage({ id: 'signin.link.lost-password' })}
                </MDBNavLink>
              </MDBRow>

              <MDBRow className="align-items-center mt-2 justify-content-around">
                <MDBBtn onClick={() => submitForm()} color="secondary">
                  {intl.formatMessage({ id: 'button.signin' })}
                </MDBBtn>
                <span className="primary" onClick={() => this.setState({ isConnect: false })}>
                  {intl.formatMessage({ id: 'signin.link.register' })}
                </span>
              </MDBRow>
            </>
            : <div className="Form">
              <MDBInput
                type="text"
                name="name"
                value={user.name}
                label={intl.formatMessage({ id: 'driver-group.booking.form.name' })}
                onChange={handleUserInputChange}
                required
              />
              <ErrorMessage
                content={intl.formatMessage({
                  id: 'driver-group.booking.error.name'
                })}
                isVisible={errors.name}
              />
              <MDBInput
                type="text"
                name="first_name"
                value={user.first_name}
                label={intl.formatMessage({ id: 'driver-group.booking.form.first_name' })}
                onChange={handleUserInputChange}
                required
              />
              <ErrorMessage
                content={intl.formatMessage({
                  id: 'driver-group.booking.error.first_name'
                })}
                isVisible={errors.first_name}
              />
              <MDBInput
                type="email"
                name="email"
                value={user.email}
                label={intl.formatMessage({
                  id: 'driver-group.booking.form.email'
                })}
                onChange={handleUserInputChange}
                required
              />
              <ErrorMessage
                content={intl.formatMessage({
                  id: 'driver-group.booking.error.email'
                })}
                isVisible={errors.email}
              />
              <PhoneInput
                defaultCountry="FR"
                placeholder={intl.formatMessage({
                  id: 'driver-group.booking.form.phone'
                })}
                name="phone"
                className="md-form"
                value={user.phone ? user.phone : ''}
                onChange={value =>
                  handleUserInputChange({
                    target: {
                      name: 'phone',
                      value: value && value.length > 5 ? formatPhoneNumberIntl(value).replace(/\s+/g, '') : value
                    }
                  })
                }
                required
              />
              <ErrorMessage
                content={intl.formatMessage({
                  id: 'driver-group.booking.error.phone'
                })}
                isVisible={errors.phone}
              />
            </div>}
        </div>
      )
    };
  }
}

export default injectIntl(User);
