// @flow

import React, { PureComponent } from 'react';

import { IconLocation, IconCircle, IconLine } from '../../../assets';
import { Yellow } from '../../../const';

type Props = {
  style: Object
};

class LeftDesignCard extends PureComponent<Props> {
  render() {
    const { style } = this.props;
    return (
      <div
        className="d-flex flex-column justify-content-around bg-secondary rounded-right position-absolute"
        style={style}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <div className="rounded-circle d-flex justify-content-center aligns-items-center">
          <IconCircle color={Yellow} />
        </div>
        <div className="rounded-circle d-flex flex-column justify-content-center aligns-items-center">
          <IconLine color={Yellow} />
        </div>
        <div className="rounded-circle d-flex justify-content-center aligns-items-center">
          <IconLocation color={Yellow} />
        </div>
      </div>
    );
  }
}

export default LeftDesignCard;
