import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M4.727 18A.739.739 0 014 17.25c0-.414.326-.75.727-.75h14.546c.401 0 .727.336.727.75s-.326.75-.727.75H4.727zm0-10.5A.739.739 0 014 6.75c0-.414.326-.75.727-.75h14.546c.401 0 .727.336.727.75s-.326.75-.727.75H4.727zm0 5.25A.739.739 0 014 12c0-.414.326-.75.727-.75h14.546c.401 0 .727.336.727.75s-.326.75-.727.75H4.727z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
