// @flow

import React, { PureComponent } from 'react';

import { getShorterAddress } from '../../../../../helpers/address';

type Props = {
  data: Object
};

class Address extends PureComponent<Props> {
  render() {
    const { data } = this.props;
    const shorterAddressDeparture = getShorterAddress(data.departure_address);
    const shorterAddressArrival = getShorterAddress(data.arrival_address);

    return (
      <div
        className="d-flex justify-content-start align-items-center position-relative mt-3 overflow-hidden"
        style={{ width: '55%' }}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <div className="d-flex flex-column align-items-start ml-3">
          <p className="FirstLineAddress mb-0" style={{ whiteSpace: 'nowrap' }}>
          {shorterAddressDeparture.split(",")[0]}
          </p>
          <p className="grey-text mb-1" style={{ whiteSpace: 'nowrap' }}>
          {shorterAddressDeparture.split(",")[1]}
          </p>
          <span className="block-example border-bottom border-light mb-1" style={{ width: '100%', display: 'block' }} />
          <p className="FirstLineAddress mb-0" style={{ whiteSpace: 'nowrap' }}>
          {shorterAddressArrival.split(",")[0]}
          </p>
          <p className="grey-text" style={{ whiteSpace: 'nowrap' }}>
          {shorterAddressArrival.split(",")[1]}
          </p>
        </div>
      </div>
    );
  }
}

export default Address;
