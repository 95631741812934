import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M11.993 4.001c-3.37 0-5.364 2.147-5.364 2.147a.621.621 0 0 0-.116.62l2.778 7.776a.62.62 0 0 0 1.023.232s1.57-1.49 2.894-1.49c.84 0 1.334.138 1.853.29.518.153 1.087.33 1.852.33 1.531 0 2.759-.697 2.759-.697a.62.62 0 0 0 .328-.542V4.62a.62.62 0 0 0-.35-.558.613.613 0 0 0-.653.075s-1.445 1.102-2.682 1.102c-.62 0-1.117-.246-1.775-.56C13.88 4.363 13.073 4 11.993 4zm0 1.238c.169 0 .33.02.482.039l.521 1.992c.56 0 2.122.774 2.122.774l-.443-1.954c.477.206.998.387 1.64.387.325 0 .63-.048.926-.116l.154 1.915s.6-.16 1.37-.619v2.573c-.649.278-1.177.368-1.177.368l.155 1.973c-.251.05-.517.096-.83.096-.28 0-.521-.026-.753-.077l-.463-2.012c-.581-.152-1.52-.423-1.948-.483l.54 2.03a7.1 7.1 0 0 0-1.08-.077c-.452 0-.85.083-1.216.194l-.733-1.935c-.68.264-1.334.76-1.776 1.122L8.481 8.625c.878-.663 1.756-.987 1.756-.987l-.753-1.934c.647-.264 1.474-.465 2.509-.465zm-1.756 2.399l1.023 2.67s.83-.29 1.389-.29c.56 0 1.1.077 1.1.077l-.753-2.825s-.2-.038-.463-.038c-1.254 0-2.296.406-2.296.406zm5.46 2.94s.586.116.984.116.907-.096.907-.096l-.193-2.322s-.38.116-.849.116c-.728 0-1.428-.348-1.428-.348l.58 2.534zM5.548 5.646a.635.635 0 0 0-.25.019l-.348.097a.61.61 0 0 0-.231.077l-.27.077a.63.63 0 0 0-.425.774.626.626 0 0 0 .637.445l4.457 12.38a.616.616 0 0 0 1.085.252.62.62 0 0 0 .072-.658L5.838 6.767a.64.64 0 0 0 .231-.677.62.62 0 0 0-.52-.444z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
