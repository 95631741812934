/**
 * Catalogue des Icones personnalisées
 */
import iconLocation from './img/icons/iconLocation';
import iconCar from './img/icons/iconCar';
import iconPassenger from './img/icons/iconPassenger';
import iconGPS from './img/icons/iconGPS';
import iconInfo from './img/icons/iconInformation';
import iconCheck from './img/icons/iconCheck';
import iconCheckBG from './img/icons/iconCheckBG';
import iconDeparture from './img/icons/iconDeparture';
import iconThreeDots from './img/icons/iconThreeDots';
import iconThreeDotsH from './img/icons/iconThreeDotsH';

import iconClose from './img/icons/iconClose';
import iconLongStopWatch from './img/icons/iconLongStopWatch';
import iconLess from './img/icons/iconLess';
import iconPlus from './img/icons/iconPlus';
import iconPlusSearch from './img/icons/iconPlusSearch';
import iconSwipeR from './img/icons/iconSwipeR';
import iconPhone from './img/icons/iconPhone';
import iconEmail from './img/icons/iconEmail';
import iconWebsite from './img/icons/iconWebsite';
import iconMessage from './img/icons/iconMessage';
import iconLetter from './img/icons/iconLetter';
import iconPen from './img/icons/iconPen';
import iconMasterCard from './img/icons/iconMasterCard';
import iconCash from './img/icons/iconCash';
import iconExit from './img/icons/iconExit';
import iconMenu from './img/icons/iconMenu';
import iconWheelChair from './img/icons/iconWheelChair';

import iconFacebook from './img/icons/iconFacebook';
import iconLinkedin from './img/icons/iconLinkedin';
import iconInstagramOutline from './img/icons/iconInstagram/Outline';
import iconInstagram from './img/icons/iconInstagram/Solid';
import iconTwitter from './img/icons/iconTwitter';
import iconTripadvisor from './img/icons/iconTripadvisor';

import iconArrowUp from './img/icons/iconArrowU';
import iconArrowDown from './img/icons/iconArrowD';

import iconLeaf from './img/icons/iconLeaf';
import iconVan from './img/icons/iconVan';
import iconPlane from './img/icons/iconPlane';
import iconVip from './img/icons/iconVIP';
import iconTaxi from './img/icons/iconTaxi';

import iconDateCalendar from './img/icons/iconDateCalendar';
import iconHour from './img/icons/iconHour';
import iconAntenna from './img/icons/iconAntenna';
import iconStatus from './img/icons/iconStatus';

import iconCircle from './img/icons/iconCircle';
import iconLine from './img/icons/iconLine';
import iconHospital from './img/icons/iconHospital';

import iconCustomerSupport from './img/icons/iconCustomerSupport';

import iconFinish from './img/icons/iconFinish';
import iconQuickHour from './img/icons/iconQuickHour';

import iconSilhouette from './img/icons/iconSilhouette';

import iconDollar from './img/icons/iconDollar';

import iconToggle from './img/icons/iconToggle';

import iconLongArrowL from './img/icons/iconLongArrowL';
import iconDriver from './img/icons/iconDriver';

import logo from './img/logo';

export const Logo = logo;
export const IconDollar = iconDollar;
export const IconToggle = iconToggle;

export const IconExit = iconExit;
export const IconCash = iconCash;
export const IconMasterCard = iconMasterCard;
export const IconPen = iconPen;
export const IconPhone = iconPhone;
export const IconMessage = iconMessage;
export const IconLetter = iconLetter;
export const IconEmail = iconEmail;
export const IconWebsite = iconWebsite;
export const IconSwipeR = iconSwipeR;
export const IconLess = iconLess;
export const IconPlus = iconPlus;
export const IconLongStopWatch = iconLongStopWatch;
export const IconLocation = iconLocation;

export const IconMenu = iconMenu;
export const IconCar = iconCar;
export const IconPassenger = iconPassenger;
export const IconGPS = iconGPS;
export const IconInfo = iconInfo;
export const IconCheck = iconCheck;
export const IconCheckBG = iconCheckBG;
export const IconDeparture = iconDeparture;
export const IconLongArrowL = iconLongArrowL;

export const IconThreeDots = iconThreeDots;

export const IconThreeDotsH = iconThreeDotsH;

export const IconClose = iconClose;

export const IconFacebook = iconFacebook;
export const IconLinkedin = iconLinkedin;
export const IconInstagramOutline = iconInstagramOutline;
export const IconInstagram = iconInstagram;
export const IconTwitter = iconTwitter;
export const IconTripadvisor = iconTripadvisor;

export const IconArrowUp = iconArrowUp;
export const IconArrowDown = iconArrowDown;

export const IconLeaf = iconLeaf;
export const IconVip = iconVip;
export const IconVan = iconVan;
export const IconTaxi = iconTaxi;
export const IconPlane = iconPlane;
export const IconWheelChair = iconWheelChair;

export const IconDateCalendar = iconDateCalendar;
export const IconHour = iconHour;

export const IconCircle = iconCircle;
export const IconLine = iconLine;
export const IconHospital = iconHospital;

export const IconCustomerSupport = iconCustomerSupport;

export const IconQuickHour = iconQuickHour;
export const IconFinish = iconFinish;

export const IconSilhouette = iconSilhouette;
export const IconDriver = iconDriver;

export const IconAntenna = iconAntenna;
export const IconStatus = iconStatus;
export const IconPlusSearch = iconPlusSearch;
