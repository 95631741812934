import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    size
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <path
        d="M7.214 4.004c-.28-.023-.591.058-.878.26-.253.176-.798.609-1.308 1.099a6.462 6.462 0 00-.684.748c-.189.25-.354.478-.344.81.01.299.04 1.18.675 2.532.635 1.35 1.866 3.203 4.267 5.606 2.402 2.402 4.255 3.633 5.605 4.268 1.35.635 2.234.665 2.532.675.33.01.56-.155.81-.344.25-.189.503-.43.748-.684.488-.51.921-1.055 1.097-1.308.402-.572.335-1.248-.112-1.562a201.114 201.114 0 00-3.256-2.157c-.395-.241-.849-.132-1.255.083-.32.168-1.184.664-1.431.806-.186-.118-.892-.588-2.41-2.106-1.519-1.518-1.988-2.223-2.105-2.41.142-.246.636-1.107.806-1.432.213-.405.332-.863.078-1.26-.106-.17-.59-.907-1.079-1.646-.49-.74-.954-1.442-1.075-1.61a.918.918 0 00-.681-.368zm-.067.742a.4.4 0 01.159.053c.058.08.568.845 1.056 1.583.489.74.987 1.498 1.074 1.636.014.021.034.262-.108.53v.002c-.16.305-.909 1.613-.909 1.613l-.104.183.107.182s.558.941 2.333 2.716c1.776 1.775 2.717 2.335 2.717 2.335l.182.106.183-.104s1.305-.746 1.613-.908c.269-.14.51-.122.539-.105.25.154 3.044 2.01 3.216 2.13.005.005.157.235-.064.55h-.002c-.133.192-.57.747-1.026 1.221a6.004 6.004 0 01-.662.608c-.199.15-.376.196-.348.198-.31-.01-.992-.017-2.246-.607-1.254-.588-3.043-1.765-5.401-4.124-2.357-2.357-3.534-4.147-4.124-5.402-.588-1.253-.595-1.936-.605-2.245.002.029.049-.15.198-.349.149-.199.369-.434.606-.663.476-.456 1.03-.893 1.221-1.027v.002a.56.56 0 01.395-.114z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
  size: 24,
};

export default SvgComponent;
