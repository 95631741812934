/**
 * Gestion des erreurs par Sentry
 */

import * as Sentry from '@sentry/react';

class ErrorManager {
  static Instance = new ErrorManager();

  Handle(error) {
    Sentry.withScope(scope => {
      //Erreur Nebulea API - Feathers
      if (error.response && error.response.data && error.response.data.message) {
        scope.setExtra("response", error.response.data);
        Sentry.captureException(new Error(error.response.data.message));
      }else if(error.response && error.response.status !== 502) {
        scope.setExtra("error", error.response);
        Sentry.captureException(new Error(JSON.stringify(error.response)));
      }
    });
  }
}

export default ErrorManager;
