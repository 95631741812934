import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M8.01904043,8.00036486 C7.82245009,7.99386655 7.6336708,8.07444651 7.50217652,8.22001062 L4,11.9994787 L7.50217652,15.7789467 C7.6623133,15.9570027 7.90707509,16.0362834 8.14272333,15.9842962 C8.37706972,15.9323091 8.56584868,15.7581518 8.63615277,15.5281086 C8.70645652,15.2980655 8.64787,15.048527 8.48122368,14.8743698 L6.43329665,12.6649146 L19.3236502,12.6649146 C19.5645062,12.6688137 19.7871353,12.5427449 19.9082142,12.3347961 C20.0305953,12.128147 20.0305953,11.8708103 19.9082142,11.6641615 C19.7871353,11.4562128 19.5645062,11.3301436 19.3236502,11.3340428 L6.43329665,11.3340428 L8.48122368,9.12458757 C8.66219125,8.93353459 8.71426823,8.65540334 8.61402013,8.41236319 C8.51507388,8.17062296 8.28202932,8.00816277 8.01904043,8.00036486 Z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
