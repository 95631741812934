// @flow

import React, { Fragment } from 'react';
import { withMobileContext } from '../../../modules/MobileContext';
import { injectIntl } from 'react-intl';

type Props = {
  dg: Object,
  bg: Object,
  sm: boolean,
  md: boolean,
  intl: Object
};

class DriverGroupHeader extends React.PureComponent<Props> {
  render() {
    const { dg, bg, intl } = this.props;
    return (
      <Fragment>
        <div className="Details">
          <div>
            <h5 className="text-white">{intl.formatMessage({ id: 'driver-group.titles.header' })}</h5>
            <div className="h1 text-uppercase">{dg.brand}</div>
            {bg && bg._id &&
              <div className="">
                <div className="cardTitlePadding align-text-top ml-3 mb-1">avec</div>
                <div className="h1 text-uppercase ml-2">{bg.name}</div>
              </div>
            }
          </div>
          <h4><b>{dg.main_work_zone}</b></h4>
        </div>
      </Fragment>
    );
  }
}

export default withMobileContext(injectIntl(DriverGroupHeader));
