/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { SET_USER, SET_DRIVER_GROUP } from './types';
import { IS_FETCHING, IS_NOT_FETCHING } from '../loading/types';
import { ADD_ERROR, REMOVE_ERROR } from '../errors/types';
import API, { setAuthorization } from '../../components/Api';
import ErrorManager from '../../modules/ErrorManager';

import * as hooks from './hooks';
const { getUserRoles } = hooks;

/**
 * Modification de l'utilisateur courant avec de nouvelles valeurs
 * @param {Object} values
 */
export function patchUser(values: Object) {
  return (dispatch: Function) => {
    dispatch({ type: IS_FETCHING });
    dispatch({ type: REMOVE_ERROR });
    API.patch(`users/${values._id}`, values)
      .then(res => {
        const { data } = res;

        data.roles = getUserRoles(data);

        dispatch({
          type: SET_USER,
          values: data
        });
      })
      .catch(err => {
        ErrorManager.Instance.Handle(err);
        dispatch({
          type: ADD_ERROR,
          values: err.response ? err.response.data : err
        });
      })
      .then(() => {
        dispatch({ type: IS_NOT_FETCHING });
      });
  };
}
