// @flow
import moment from 'moment';
import axios from 'axios';
import Api from '../Api';
import { mapboxApiUrl, mapboxAccesToken, gmapsApiUrl } from '../../const';

/**
 * Fonction permettant, pour une adresse GPS donnée d'en déduire son adresse "humaine"
 */
export function reverseGeocoding(longitude: number, latitude: number, callback: Function) {
  axios
    .get(`${mapboxApiUrl}geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxAccesToken}`)
    .then((res) => {
      const { data } = res;

      if (data.features && data.features.length > 0) {
        callback(null, data.features[0].place_name);
      } else {
        callback(null, null);
      }
    });
}

/**
 * Fonction permettant de générer un trajet entre 2 points
 */
export function getDirections(
  startPoint: Object,
  endPoint: Object,
  departure_date: String,
  stops: Object,
  service: String,
  callback: Function
) {

  // Price for basic search (driver_mode traffic = normal, waypoints < 10 points, sans departure date) => 5 $ les 1000 requests
  // Sinon c'est 10 $ les 1000 requests

  let url = `google/maps/directions/?departure_longitude=${startPoint.longitude}&departure_latitude=${startPoint.latitude}`;
  url += `&arrival_longitude=${endPoint.longitude}&arrival_latitude=${endPoint.latitude}`;

  if (departure_date && moment(departure_date).diff(moment(), 'hours') > 2) { //si départ dans moins de 2h, on search sans traffic => moins cher
    url += `&departure_date=${moment(departure_date).toISOString()}`;
  }

  if (service) {
    url += `&service=${service}`;
  }

  if (stops.length > 0) {
    url += '&waypoints=';
    for (const stop of stops) {
      if (stop.latitude !== null && stop.longitude !== null) {
        url += `via%3A${stop.latitude}%2C${stop.longitude}%7C`;
      }
    }
    url = url.substring(0, url.length - 3);
  }

  Api.get(url).then((res) => {
    callback(null, res.data);
  });
}

export async function getAsyncDirections(
  startPoint: Object,
  endPoint: Object,
  departure_date: String,
  stops: Object,
  service: String
) {

  // Price for basic search (driver_mode traffic = normal, waypoints < 10 points, sans departure date) => 5 $ les 1000 requests
  // Sinon c'est 10 $ les 1000 requests

  let url = `google/maps/directions/?departure_longitude=${startPoint.longitude}&departure_latitude=${startPoint.latitude}`;
  url += `&arrival_longitude=${endPoint.longitude}&arrival_latitude=${endPoint.latitude}`;

  if (departure_date && moment(departure_date).diff(moment(), 'hours') > 2) { //si départ dans moins de 2h, on search sans traffic => moins cher
    url += `&departure_date=${moment(departure_date).toISOString()}`;
  }

  if (service) {
    url += `&service=${service}`;
  }

  if (stops.length > 0) {
    url += '&waypoints=';
    for (const stop of stops) {
      if (stop.latitude !== null && stop.longitude !== null) {
        url += `via%3A${stop.latitude}%2C${stop.longitude}%7C`;
      }
    }
    url = url.substring(0, url.length - 3);
  }

  const res = await Api.get(url);
  return res.data
}

/**
 * Fonction permettant, pour une adresse donnée d'en retourner les infos GPS
 */
export function getGeoCoding(address: string, proximity: Object, cb: function) {
  // Il y a bien une adresse de saisie
  if (address) {
    let url = `google/maps/geocoding/?address=${address}`
    if (proximity) {
      url += `region=fr`;
    }

    Api.get(url)
      .then((res) => {

        if (res.data.results.length > 0) {
          //Mapbox format
          const place = res.data.results[0];
          let item = place;
          item.place_name = place.formatted_address;
          item.center = [place.geometry.location.lng, place.geometry.location.lat];
          cb(null, item);
          return true;
        }

        cb(new Error('No address found'));
        return false;
      })
      .catch(cb);

    let request = {
      address: address
    };

    if (proximity) {
      request.region = 'fr';
    }

    /*global google*/
    new google.maps.Geocoder().geocode(request, (results, status) => {
      if (status === 'OK') {
        //Mapbox format
        const place = results[0];
        let item = place;
        item.place_name = place.formatted_address;
        item.center = [place.geometry.location.lng(), place.geometry.location.lat()];
        cb(null, item);
      } else {
        cb(new Error('No address found'));
      }
    });
  }
}
