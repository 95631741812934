import moment from "moment";

/**
 * Invoice naming function for download or email
 * @param {Object} invoice invoice
 */

export default function nameInvoice(
  invoice: Object
) {
  const replaceFunction = (format) => {
    let title = format;
    // Replace variables
    title = title.replace(/{{sender_business_tax_id}}/gi, invoice.sender.business_tax_id ? invoice.sender.business_tax_id : "");
    title = title.replace(/{{sender_business_vat_id}}/gi, invoice.sender.business_vat_id ? invoice.sender.business_vat_id : "");
    title = title.replace(/{{receiver_business_tax_id}}/gi, invoice.receiver.business_tax_id ? invoice.receiver.business_tax_id : "");
    title = title.replace(/{{receiver_business_vat_id}}/gi, invoice.receiver.business_vat_id ? invoice.receiver.business_vat_id : "");
    title = title.replace(/{{number}}/gi, invoice.number);
    title = title.replace(/{{ref}}/gi, invoice.items.length > 0 && invoice.items[0] ? invoice.items[0].code_promo : "");
    title = title.replace(/{{booking_ref}}/gi, invoice.items.length > 0 && invoice.items[0] ? invoice.items[0].booking_ref : "");
    return title;
  }

  if (invoice.receiver.invoice_title_format) {
    return replaceFunction(invoice.receiver.invoice_title_format);
  } else if (invoice.sender.invoice_title_format) {
    return replaceFunction(invoice.sender.invoice_title_format);
  } else if (invoice.items.length > 0 && invoice.items[0]._id) {
    let dates = [];
    let title = null;

    // CHAQUE COURSE DE LA FACTURE
    invoice.items.map((ride) => {
      if (ride.departure_date) dates.push(ride.departure_date);
    });
    dates.sort();

    title = invoice.title ? invoice.title : dates.length === 1
      ? 'Course du ' + moment(dates[0]).format("DD/MM/YY")
      : dates[0].split('T')[0] === dates[dates.length - 1].split('T')[0]
        ? 'Courses du ' + moment(dates[0]).format("DD/MM/YY")
        : 'Courses du ' + moment(dates[0]).format("DD/MM/YY")
        + ' au ' +
        moment(dates[dates.length - 1]).format("DD/MM/YY");
    return title;
  } else {
    return "Facture du " + invoice.issue_date;
    //    title = "Facture_" + (invoice.sender ? invoice.sender.name || invoice.sender.legal_name : "") + "_" + invoice.number;
  }
}
