import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    background,
    size
  } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <circle
        stroke={color}
        fill={color}
        fillRule="nonzero"
        cx={13}
        cy={13}
        r={5}
        strokeWidth="2"
      />
      <path
        d="M12 20a8 8 0 110-16 8 8 0 010 16zm0-13a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-2 4v1h1v4h-1v1h4v-1h-1v-4.5a.5.5 0 00-.5-.5H10z"
        fill={background}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.number,
};

SvgComponent.defaultProps = {
  color: '#ffffff',
  background: '#0b060d',
  size: 24,
};

export default SvgComponent;
