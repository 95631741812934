/**
 * Liste les roles de l'utilisateur vis-à-vis du DG
 * @param {Object} user
 */
export function getUserRoles(user: Object) {
  const roles = [];
  if (user.driver_groups && user.driver_groups.length > 0) {
    for (let i = 0; i < user.driver_groups.length; i += 1) {
      if (user.driver_groups[i].role === 'MANAGER') {
        roles.push('manager');
      }
      if (user.driver_groups[i].role === 'OPERATOR') {
        roles.push('driver');
      }
      if(user.driver_groups[i].role === 'EXTERN'){
        roles.push('extern');
      }
    }
  }

  if (user.business_group) {
    roles.push('business');
  }

  if (user.admin) {
    roles.push('admin');
  }

  if (roles.length === 0) {
    roles.push('passenger');
  }

  return roles;
}
