import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17.776 10c-1.228 0-2.224 1.119-2.224 2.5s.996 2.5 2.224 2.5C19.005 15 20 13.881 20 12.5s-.995-2.5-2.224-2.5zm0 3.94c-.706 0-1.28-.646-1.28-1.44 0-.794.574-1.44 1.28-1.44.706 0 1.28.646 1.28 1.44 0 .794-.573 1.44-1.28 1.44zM6.224 10c1.228 0 2.224 1.119 2.224 2.5S7.452 15 6.224 15C4.995 15 4 13.881 4 12.5S4.995 10 6.224 10zm0 3.94c.706 0 1.28-.646 1.28-1.44 0-.794-.574-1.44-1.28-1.44-.706 0-1.28.646-1.28 1.44 0 .794.574 1.44 1.28 1.44zM12 10c1.228 0 2.224 1.119 2.224 2.5S13.23 15 12 15c-1.228 0-2.224-1.119-2.224-2.5S10.772 10 12 10zm0 3.94c.706 0 1.28-.646 1.28-1.44 0-.794-.574-1.44-1.28-1.44-.706 0-1.28.646-1.28 1.44 0 .794.574 1.44 1.28 1.44z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
