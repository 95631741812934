import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M14.4,4 C13.3336,4 12.3401872,4.3042 11.4921872,4.825 C11.6601872,4.813 11.8288,4.8 12,4.8 C15.9768,4.8 19.2,8.0232 19.2,12 C19.2,12.1712 19.187,12.3398128 19.175,12.5078128 C19.6958,11.6598128 20,10.6664 20,9.6 C20,6.512 17.4872,4 14.4,4 Z M12,6.4 C10.9336,6.4 9.94018752,6.7042 9.09218752,7.225 C9.26018752,7.213 9.4288,7.2 9.6,7.2 C13.5768,7.2 16.8,10.4232 16.8,14.4 C16.8,14.5712 16.787,14.7382504 16.775,14.9062496 C17.2958,14.0582504 17.6,13.0656 17.6,12 C17.6,8.912 15.0872,6.4 12,6.4 Z M9.6,8.8 C6.51668112,8.8 4,11.3166808 4,14.4 C4,17.4833192 6.51668112,20 9.6,20 C12.6833192,20 15.2,17.4833192 15.2,14.4 C15.2,11.3166808 12.6833192,8.8 9.6,8.8 Z M9.6,10.4 C11.8186144,10.4 13.6,12.1813856 13.6,14.4 C13.6,16.6186144 11.8186144,18.4 9.6,18.4 C7.38138528,18.4 5.6,16.6186144 5.6,14.4 C5.6,12.1813856 7.38138528,10.4 9.6,10.4 Z"        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
