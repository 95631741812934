import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    background,
  } = props;

  return (
    <svg width={24} height={24} {...props}>
      <circle
        stroke={background}
        fill={background}
        fillRule="nonzero"
        cx={12}
        cy={12}
        r={10}
        strokeWidth="2"
      />
      <path
        d="M17.563 6.908l-.007.004a.314.314 0 0 0-.247-.12.308.308 0 0 0-.315.3c0 .05.017.096.04.138l-.005.003.02.02a.303.303 0 0 0 .1.092c1.377 1.278 2.236 3.062 2.236 5.034 0 3.88-3.313 7.035-7.384 7.035-4.072 0-7.385-3.156-7.385-7.035 0-3.01 2-5.581 4.797-6.583.213.247.531.409.893.409.639 0 1.157-.494 1.157-1.103 0-.608-.518-1.102-1.157-1.102-.64 0-1.157.494-1.157 1.102 0 .054.008.106.016.158C6.15 6.352 4 9.129 4 12.38 4 16.581 7.589 20 12 20s8-3.418 8-7.62c0-2.145-.935-4.086-2.437-5.472zm-7.258-2.441c.367 0 .666.285.666.635 0 .35-.299.634-.666.634-.367 0-.666-.284-.666-.634 0-.35.298-.635.666-.635zM9.013 9.534a.319.319 0 0 1 .435 0L12 11.964l2.552-2.43a.318.318 0 0 1 .435 0c.12.114.12.3 0 .414l-2.552 2.43 2.552 2.432c.12.114.12.3 0 .414a.316.316 0 0 1-.217.086.314.314 0 0 1-.218-.086L12 12.794l-2.552 2.43a.316.316 0 0 1-.435 0 .283.283 0 0 1 0-.414l2.552-2.43-2.552-2.431a.283.283 0 0 1 0-.415z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#FFFFFF',
  background: '#0B060D',
};

export default SvgComponent;
