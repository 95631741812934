import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17.418 5.018a.782.782 0 0 0-.534.325l-6.98 9.47-3.29-2.967c-.284-.39-.829-.421-1.112-.162l-1.29 1.179c-.283.39-.283.92 0 1.178l4.98 4.553c.284.132.684.406 1.112.406.283 0 .74-.137 1.022-.528L19.863 7.01c.284-.392.122-.757-.444-1.017l-1.423-.934c-.145-.066-.367-.072-.578-.041z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
