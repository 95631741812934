import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M18.49 15.065H5.51a.623.623 0 0 1-.604-.624c0-.338.277-.624.603-.624h12.982c.327 0 .603.286.603.624a.622.622 0 0 1-.603.624zm-10.692-4.54l.016-.043.014-.042c.427-1.388.856-1.505 1.224-1.505h5.842c.477 0 .637 0 1.248 1.57l.006.02.974 2.357H6.826l.972-2.358zm10.693 2.357h-.387l-1.123-2.726C16.403 8.672 16.042 8 14.894 8H9.052c-1.149 0-1.707.921-2.087 2.156l-1.123 2.726h-.333c-.83 0-1.509.701-1.509 1.559C4 15.298 4.68 16 5.51 16h12.98c.83 0 1.51-.702 1.51-1.56 0-.857-.68-1.558-1.51-1.558z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
