import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M4 7v11h16V7H4zm2.654 1.222h10.692L12 11.755 6.654 8.222zm-1.423.535l6.423 4.259.346.21.346-.21 6.423-4.26v8.022H5.231V8.757z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};


SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
