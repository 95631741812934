import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M10.636 5.476a6.858 6.858 0 0 0-3.8 1.93l-.226.226a6.828 6.828 0 0 0-2.019 4.865 6.82 6.82 0 0 0 1.692 4.514l.12.121L4.105 19.4a.35.35 0 0 0 .247.601c.09 0 .18-.034.248-.102l2.302-2.27.06.062a6.85 6.85 0 0 0 4.524 1.687 6.855 6.855 0 0 0 4.874-2.015l.226-.226a6.833 6.833 0 0 0 1.935-3.792L20 4l-9.364 1.476zm6.935 7.719a5.879 5.879 0 0 1-1.665 3.263l-.226.226a5.95 5.95 0 0 1-8.035.326l-.06-.06 6.152-6.138c.166.083.35.135.55.135.68 0 1.232-.551 1.232-1.23a1.231 1.231 0 0 0-2.465 0c0 .224.065.431.17.613l-6.136 6.124-.123-.123a5.92 5.92 0 0 1 .326-8.019l.226-.226a5.897 5.897 0 0 1 3.27-1.661l8.056-1.271-1.272 8.041zm-3.994-3.48a.71.71 0 0 1 1.418 0 .71.71 0 0 1-1.418 0z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
