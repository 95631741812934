/** API */
//export const apiUrl = 'http://localhost:3030/'; //https://nebulea.eu/api/ http://localhost:3030/
export const apiUrl = 'https://nebulea.eu/api/'; //https://nebulea.eu/api/ http://localhost:3030/

/** MAPBOX */
export const mapboxAccesToken =
  'pk.eyJ1IjoiYWxleGFuZHJlbmVidWxlYSIsImEiOiJjanc5NWJvd3MwY3ZxNDNteDBvdWQ1eTlzIn0.3_cS4JewhuJENlZjcWk33Q';
export const mapboxApiUrl = 'https://api.mapbox.com/';

/** STRIPE */
export const stripeApiKey = 'pk_live_pGiWoi4XuIRGVw8doWGwf2Ch'; //pk_test_eMXby7bWV0cf2KugGRwGaCRX pk_live_pGiWoi4XuIRGVw8doWGwf2Ch

export const zoomOnePoint = 14;
export const zoomTwoPoint = 10;

export const Yellow = 'rgb(252, 235, 38)';
export const Blue = '#101526';
export const White = 'rgb(255,255,255)';
export const Black = '#0B060D';
export const LightGrey = '#878488';
export const LightBlack = '#2D3140';
export const Red = '#dc3545';
export const Green = '#28a745';

export const interval = 5000;

/** Liste des DGs autorisés à utiliser le mode inscription / connexion sur nebulea.eu/dg/:id */
export const BookingPlatformAllowed = ['6540e0d89bfd116709a6c771', '636a32d7611f64dcf3896252', '5d249a47274bb33b61e54cbe', '63e2d19c40af4b33f38034e2', '63737312bc984006f1148cbc'];

/** Liste des DGs autorisés à utiliser le mode Rapports d'activités et autofactures */
export const ExpertsAllowed = ["667185f6739d9f7aecfdfdc0", "65d9aaf4f655706c245f6049","6217758a659fd472a6bbd7ec", "61750c1a73aabb77e3ae8fd7", "635437e657f3da0bad48dacd", "5d249a47274bb33b61e54cbe", "602541c92c742139c38d61b1", "63daf8ff5243c4458f634253", "64196a172816a34220d1020f", "63e26e2440af4b33f380334a", "63d2b04a261f9a1e5076687c"];

/** Prépaiement obligatoire nebulea.eu/dg/:id */
export const PrepaymentRequired = ['636a32d7611f64dcf3896252', '5d249a47274bb33b61e54cbe', '63e2d19c40af4b33f38034e2', '61750c1a73aabb77e3ae8fd7', '63e26e2440af4b33f380334a', '6432edbb36729a71e8b33942'];