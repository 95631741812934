import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M18.095 4H5.905A1.905 1.905 0 0 0 4 5.905v12.19C4 19.147 4.853 20 5.905 20h12.19A1.905 1.905 0 0 0 20 18.095V5.905A1.905 1.905 0 0 0 18.095 4zm-9.143 6.095v7.238H6.667v-7.238h2.285zM6.667 7.99c0-.534.457-.941 1.143-.941.685 0 1.116.407 1.142.94 0 .534-.426.964-1.142.964-.686 0-1.143-.43-1.143-.963zm10.666 9.344h-2.285v-3.81c0-.761-.381-1.523-1.334-1.538h-.03c-.922 0-1.303.785-1.303 1.539v3.81h-2.286v-7.239h2.286v.975s.735-.975 2.213-.975c1.513 0 2.74 1.04 2.74 3.147v4.091z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
