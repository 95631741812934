// @flow
import React from 'react';
import { MDBInput } from 'mdbreact';

// Traduction
import { injectIntl } from 'react-intl';
import { IconArrowUp, IconArrowDown } from '../../../../assets';
import ErrorMessage from '../../Generic/ErrorMessage';

type Props = {
  intl: Object,
  booking: Object,
  handleBookingInputChange: Function
};

class MoreInformations extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      moreInformationsOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.mandatoryFlightTrain && this.props.mandatoryFlightTrain) {
      this.setState({ moreInformationsOpen: true })
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      moreInformationsOpen: !prevState.moreInformationsOpen
    }));
  };

  render() {
    const { intl, booking, handleBookingInputChange, mandatoryFlightTrain, errors } = this.props;
    const { moreInformationsOpen } = this.state;

    return (
      <div className="MoreInformations">
        <div
          className="Button"
          onClick={this.toggle}
          onKeyPress={() => { }}
          role="button"
          tabIndex="0"
        >
          {intl.formatMessage({
            id: 'driver-group.booking.form.more-informations'
          })}
          {moreInformationsOpen ? <IconArrowUp /> : <IconArrowDown />}
        </div>
        <div className={`Content ${moreInformationsOpen ? '' : 'hidden'}`}>
          <MDBInput
            type="text"
            name="flight_train_number"
            value={booking.flight_train_number || ''}
            label={intl.formatMessage({
              id: 'driver-group.booking.form.flight_train_number'
            })}
            onChange={handleBookingInputChange}
          />
           <ErrorMessage
            content={intl.formatMessage({
              id: 'driver-group.booking.error.flight_train_number'
            })}
            isVisible={booking.flight_train_number ? false : mandatoryFlightTrain}
          />
          {booking.return_date ? <MDBInput
            type="text"
            name="flight_train_number_return"
            value={booking.flight_train_number_return || ''}
            label={intl.formatMessage({
              id: 'driver-group.booking.form.flight_train_number_return'
            })}
            onChange={handleBookingInputChange}
          /> : null}
          <MDBInput
            type="text"
            name="code_promo"
            value={booking.code_promo || ''}
            label={intl.formatMessage({
              id: 'driver-group.booking.form.code_promo'
            })}
            onChange={handleBookingInputChange}
          // required
          />
          <MDBInput
            type="text"
            name="comment_public"
            value={booking.comment_public || ''}
            label={intl.formatMessage({
              id: 'driver-group.booking.form.comment_public'
            })}
            onChange={handleBookingInputChange}
          // required
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(MoreInformations);
