import { SET_DRIVER_GROUP } from './types';
/**
 * Modifie le DG sélectionné par l'utilisateur
 * @param {Object} DriverGroup Nouveau DG
 */
export function setDriverGroup(DriverGroup: Object) {
  return (dispatch: Function) => {
    dispatch({
      type: SET_DRIVER_GROUP,
      values: DriverGroup
    });
  };
}
