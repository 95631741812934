import { MDBIcon } from 'mdbreact';
import React from 'react';
import { IconLeaf, IconVip, IconVan, IconCar, IconWheelChair, IconHospital, IconTaxi } from '../../../assets';
import { Yellow } from '../../../const';

export const ServiceIcon = {
  green: <IconLeaf color="" />,
  vip: <IconVip color="" />,
  van: <IconVan color="" />,
  standard: <IconCar color="" />,
  medical_taxi: <IconHospital color="" />,
  ambulance: <MDBIcon icon="bus-alt" />,
  vsl: <IconHospital color="" />,
  moto_taxi: <MDBIcon icon="motorcycle" />,
  van_vip: <IconVan color="" />,
  scooter_taxi: <MDBIcon icon="bicycle" />,
  monospace: <MDBIcon icon="truck-pickup" />,
  minibus: <MDBIcon icon="bus-simple" />,
  disabled_transport: <IconWheelChair color="" />
};

export const ServiceIconYellow = {
  green: <IconLeaf color={Yellow} />,
  vip: <IconVip color={Yellow} />,
  van: <IconVan color={Yellow} />,
  standard: <IconCar color={Yellow} />,
  medical_taxi: <IconHospital color={Yellow} />,
  ambulance: <MDBIcon icon="bus-alt" />,
  moto_taxi: <MDBIcon icon="motorcycle" />,
  van_vip: <IconVan color={Yellow} />,
  scooter_taxi: <MDBIcon icon="motorcycle" />,
  monospace: <MDBIcon icon="truck-pickup" />,
  minibus: <MDBIcon icon="bus-simple" />,
  disabled_transport: <IconWheelChair color={Yellow} />
};

export default ServiceIcon;
