import React from 'react';

const SvgBlack = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path opacity={0.01} fill="#fff" d="M.319 0h22.979v24H.319z" />
    <path
      d="M23.298 18.733c0 1.252-.98 2.267-2.189 2.267H2.508C1.299 21 .319 19.985.319 18.733V6.267C.32 5.015 1.3 4 2.508 4h18.601c1.209 0 2.189 1.015 2.189 2.267v12.466z"
      fill="#3F51B5"
    />
    <mask
      id="Black_svg__a"
      maskUnits="userSpaceOnUse"
      x={0}
      y={4}
      width={24}
      height={17}
    >
      <path
        d="M23.298 18.733c0 1.252-.98 2.267-2.189 2.267H2.508C1.299 21 .319 19.985.319 18.733V6.267C.32 5.015 1.3 4 2.508 4h18.601c1.209 0 2.189 1.015 2.189 2.267v12.466z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#Black_svg__a)">
      <path
        d="M14.202 18c2.908 0 5.266-2.462 5.266-5.5S17.11 7 14.202 7s-5.266 2.462-5.266 5.5 2.358 5.5 5.266 5.5z"
        fill="#FFC107"
      />
      <path
        d="M11.098 15.8a5.555 5.555 0 0 1-.62-1.1h2.805c.146-.35.26-.717.335-1.1h-3.475a5.723 5.723 0 0 1-.106-1.1h3.686c0-.377-.037-.745-.106-1.1h-3.474c.075-.383.189-.75.335-1.1h2.805a5.527 5.527 0 0 0-.62-1.1h-1.565c.23-.319.49-.617.78-.877A5.113 5.113 0 0 0 8.458 7C5.548 7 3.19 9.462 3.19 12.5S5.55 18 8.457 18c1.722 0 3.245-.866 4.206-2.2h-1.565z"
        fill="#FF3D00"
      />
    </g>
  </svg>
);

export default SvgBlack;
