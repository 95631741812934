// @flow

import React from 'react';

import { MDBRow, MDBCol } from 'mdbreact';

import { injectIntl } from 'react-intl';
import { withMobileContext } from '../../../modules/MobileContext';
import { IconInfo } from '../../../assets';
import { Black, Yellow } from '../../../const';

type Props = {
  title: any,
  sm: boolean,
  md: boolean,
  lg: boolean,
  info: ?boolean,
  intl: Object,
  autoWidth?: boolean
};

class CardTitle extends React.PureComponent<Props> {
  static defaultProps = {
    autoWidth: false
  };

  renderContent = () => {
    const { title, sm, md, lg, info, intl } = this.props;

    return !sm && !md && !lg ? (
      <h4 className="cardTitlePadding d-flex align-items-center">
        {intl.formatMessage({ id: title })}
        {info && (
          <div>
            <IconInfo background={Yellow} color={Black} />
          </div>
        )}
      </h4>
    ) : (
      <h6 className="cardTitlePadding d-flex align-items-center">
        {intl.formatMessage({ id: title })}
        {info && (
          <div>
            <IconInfo background={Yellow} color={Black} />
          </div>
        )}
      </h6>
    );
  };

  render() {
    const { autoWidth } = this.props;

    if (autoWidth) {
      return (
        <MDBRow className="mb-0">
          <MDBCol className="d-flex align-items-center">
            {this.renderContent()}
          </MDBCol>
        </MDBRow>
      );
    }
    return (
      <MDBRow className="mb-0">
        <MDBCol xs="4" md="6" className="d-flex align-items-center">
          {this.renderContent()}
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withMobileContext(injectIntl(CardTitle));
