
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import API from '../../components/Api';

/**
 * Vérification si le client paye 
 * @param {Object} values
 */
export async function checkSubscription(selectedDriverGroup: Object) {
    // Check if user is paying right 
    const now = moment();

    //Si pendant l'essai gratuit, on ne fait rien
    if (now.diff(moment(selectedDriverGroup.createdAt), 'days') < 14) {
        return { message: null, hasSubscription: true, hasSource: true, isUnpaid: false };
    }

    let errorText = '';
    let hasSubscription = true;
    let hasSource = true;
    let isUnpaid = false;

    if (selectedDriverGroup.customer_stripe_id) {
        await API.get(`stripe/customers/${selectedDriverGroup.customer_stripe_id}`).then((resSubscription) => {
            if (resSubscription.data.subscriptions.total_count === 0) {
                //meme si son abonnement est annulé, le total_count = 0
                hasSubscription = false;
                errorText += "Votre essai gratuit est terminé. Souscrivez à Nebulea pour continuer à utiliser Nebulea. ";
            } else {
               /*  if (resSubscription.data.sources.total_count === 0 || now.diff(moment(resSubscription.data.sources.data[0].card.exp_month + "-01-" + resSubscription.data.sources.data[0].card.exp_year, "MM-DD-YYYY"), 'days') > 0) {
                    hasSource = false;
                    errorText += "Ajouter un moyen de paiement pour continuer à utiliser Nebulea. "
                } */
                if (resSubscription.data.subscriptions.data[0].status !== 'active') {
                    isUnpaid = true;
                    errorText += "Votre dernière facture est impayée, payez la pour continuer à utiliser Nebulea. "
                }
            }
        })
    }

    if (!selectedDriverGroup.customer_stripe_id || errorText.length > 0) {
        return { message: errorText, hasSubscription, hasSource, isUnpaid };
    } else {
        return { message: null, hasSubscription, hasSource, isUnpaid };
    }
}