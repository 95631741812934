// @flow

import React, { Fragment } from 'react';

// UI
import CardTitle from '../Generic/CardTitle';
import ServiceIcon from '../Generic/ServiceIcon';

import { withMobileContext } from '../../../modules/MobileContext';

import { IconHour, IconDateCalendar } from '../../../assets';

type Props = {
  intl: Object,
  dg: Object,
  sm: boolean,
  md: boolean
};

class Services extends React.PureComponent<Props> {
  renderServices = () => {
    const { intl, dg, sm, md } = this.props;

    const services = [];

    if (dg.services) {
      Object.keys(dg.services).forEach(key => {
        if (dg.services[key].available === true) {
          services.push({
            name: key.toLowerCase(),
            description: dg.services[key].description
          });
        }
      });
    }

    if (services.length === 0) {
      return null;
    }

    return (
      <Fragment>
        {services.map(service => (
          <div className="Service" key={service.name}>
            {
              (!sm || !md) &&
              <div className="Picto">
                {ServiceIcon[service.name.toLowerCase()]}
              </div>
            }
            <div className="Content">
              <h5>
                {intl.formatMessage({ id: `course.service.${service.name}` })}
              </h5>
              <div className="Description">{service.description}</div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="Services Card" id="services">
        <CardTitle autoWidth title="driver-group.titles.services" />
        {this.renderServices()}
       {/*  <div className="Infos">
          <div className="Info">
            <div className="Container">
              <IconDateCalendar />
              Disponible 24h/24 - 7j/7
            </div>
          </div>
          <div className="Info">
            <div className="Container">
              <IconHour />
              Service client : 5h - 23h
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withMobileContext(Services);
