import { IS_FETCHING, IS_NOT_FETCHING } from '../actions/loading/types';

const initialState = {
  isFetching: false
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: state.isFetching + 1 };

    case IS_NOT_FETCHING:
      return { ...state, isFetching: state.isFetching - 1 };

    default:
      return state;
  }
};

export default loadingReducer;
