import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    background,
    selected,
    border,
  } = props;

  return (
    <svg width={40} height={16} fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={38}
        height={14}
        rx={7}
        stroke={border}
        fill={background}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path d={`${selected ? 'M32' : 'M8'} 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8z`} fill={color} />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  selected: PropTypes.bool,
  border: PropTypes.string,
};

SvgComponent.defaultProps = {
  background: '#FFFFFF',
  color: '#0b060d',
  border: '#0b060d',
  selected: false,
};

export default SvgComponent;
