// @flow
import React from 'react';
import { IconDateCalendar, IconPhone } from '../../../../assets';

type Props = {
  intl: Object,
  activeTab: String,
  setActiveTab: Function
};

class Header extends React.PureComponent<Props, State> {
  render() {
    const { setActiveTab, support_phone } = this.props; //intl

    return (
      <div className="Header">
        {/* <div
          onClick={() => setActiveTab('now')}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
          className={activeTab === 'now' ? 'active' : ''}
        >
          <IconQuickHour color={activeTab === 'now' ? Yellow : '#0b060d'} />
          {intl.formatMessage({ id: 'driver-groupe.booking.tabs.title.now' })}
        </div>
        <div
          onClick={() => setActiveTab('startAt')}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
          className={activeTab === 'startAt' ? 'active' : ''}
        >
          <IconDateCalendar
            color={activeTab === 'startAt' ? Yellow : '#0b060d'}
          />
          {intl.formatMessage({
            id: 'driver-groupe.booking.tabs.title.startAt'
          })}
        </div>
        <div
          onClick={() => setActiveTab('arrivalAt')}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
          className={activeTab === 'arrivalAt' ? 'active' : ''}
        >
          <IconFinish color={activeTab === 'arrivalAt' ? Yellow : '#0b060d'} />
          {intl.formatMessage({
            id: 'driver-groupe.booking.tabs.title.arrivalAt'
          })}
        </div> */}

        {/* <div role="button"
          tabIndex="0"
          className="">
          <a href={`tel:${support_phone}`} style={{ display: 'contents' }}>
            <IconPhone />
            Appelez le standard
          </a>
        </div> */}

        <div
          onClick={() => setActiveTab('startAt')}
          onKeyPress={() => { }}
          role="button"
          tabIndex="0"
        >
          <IconDateCalendar
            color='#0b060d'
          />
          Réserver en ligne
        </div>

      </div >
    );
  }
}

export default Header;
