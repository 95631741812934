import {
  SET_USER,
  SET_DRIVER_GROUP,
  TRANSFER_RIDE
} from '../actions/users/types';

const initialState = {
  infos: null,
  selectedDriverGroup: {
    _id: null
  },
  transferRide: null
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, infos: action.values };

    case SET_DRIVER_GROUP:
      return { ...state, selectedDriverGroup: action.values };

    case TRANSFER_RIDE:
      return { ...state, transferRide: action.values};

    default:
      return state;
  }
};

export default usersReducer;
