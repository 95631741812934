// @flow

import React from 'react';

import { apiUrl } from '../../../const';

type Props = {
  dg: Object
};

class Photos extends React.PureComponent<Props> {
  render() {
    const { dg } = this.props;

    if (!dg.photos || dg.photos.length === 0) {
      return null;
    }

    return (
      <div className="Photos Card" id="photos">
        {dg.photos.map((photo, index) => {
          if (index > 2) {
            return null;
          }
          return (
            <div key={photo.filename} className="Photo">
              <img
                src={`${apiUrl}images/${photo.filename}`}
                className="img-fluid"
                alt={dg.brand}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default Photos;
