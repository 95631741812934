import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M8.444 4A4.45 4.45 0 0 0 4 8.444v7.112A4.45 4.45 0 0 0 8.444 20h7.112A4.45 4.45 0 0 0 20 15.556V8.444A4.45 4.45 0 0 0 15.556 4H8.444zm0 1.778h7.112a2.67 2.67 0 0 1 2.666 2.666v7.112a2.67 2.67 0 0 1-2.666 2.666H8.444a2.67 2.67 0 0 1-2.666-2.666V8.444a2.67 2.67 0 0 1 2.666-2.666zm8 .889a.889.889 0 1 0 0 1.777.889.889 0 0 0 0-1.777zM12 7.556A4.45 4.45 0 0 0 7.556 12 4.45 4.45 0 0 0 12 16.444 4.45 4.45 0 0 0 16.444 12 4.45 4.45 0 0 0 12 7.556zm0 1.777A2.67 2.67 0 0 1 14.667 12 2.67 2.67 0 0 1 12 14.667 2.67 2.67 0 0 1 9.333 12 2.67 2.67 0 0 1 12 9.333z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
