import { SET_SEARCH_PASSENGER } from '../actions/search_passenger/types';

const initialState = null;

const searchPassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_PASSENGER:
      return action.values ;
    default:
      return state;
  }
};

export default searchPassengerReducer;
