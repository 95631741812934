// @flow

import React, { Fragment } from 'react';

// UI
import CardTitle from '../Generic/CardTitle';

import {
  IconFacebook,
  IconTwitter,
  IconInstagramOutline as IconInstagram,
  IconLinkedin,
  IconTripadvisor,
  IconWebsite
} from '../../../assets';
import { MDBIcon } from 'mdbreact';

type Props = {
  dg: Object
};

class FollowUs extends React.PureComponent<Props> {
  generateLink = (item: Object) => {
    switch (item.icon) {
      case 'phone':
        return `tel:${item.value}`;
      case 'email':
        return `mailto:${item.value}`;
      default:
        return item.value;
    }
  };

  renderPicto = (type: string) => {
    switch (type) {
      case 'website':
        return <IconWebsite />;
      case 'facebook':
        return <IconFacebook />;
      case 'instagram':
        return <IconInstagram />;
      case 'linkedin':
        return <IconLinkedin />;
      case 'tripadvisor':
        return <IconTripadvisor />;
      case 'youtube':
        return <i className="fab fa-youtube"/>;
      case 'twitter':
        return <IconTwitter />;
      default:
        return null;
    }
  };

  renderLinks = () => {
    const { dg } = this.props;

    const links = [];

    if (!dg.social_networks) {
      return null;
    }

    Object.keys(dg.social_networks).forEach(key => {
      if (dg.social_networks[key]) {
        links.push({
          type: key,
          value: dg.social_networks[key]
        });
      }
    });

    return (
      <Fragment>
        {links.map(link => (
          <div className="Link" key={link.type}>
            <a href={link.value} target="_blank" rel="noopener noreferrer">
              {this.renderPicto(link.type)}
            </a>
          </div>
        ))}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="FollowUs Card" id="follow-us">
        <CardTitle autoWidth title="driver-group.titles.follow-us" />
        <div className="Links">{this.renderLinks()}</div>
      </div>
    );
  }
}

export default FollowUs;
