// @flow

import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';

import LeftDesignCard from '../../LeftDesignCard';
import Address from './Address';
import DateAndTime from './DateAndTime';
import DriverAndStatus from './DriverAndStatus';

import history from '../../../../../history';

type Props = {
  data: Object,
  to: String,
  showDriver: boolean,
  showDriverGroup: boolean,
  showStatus: boolean,
  sm: boolean,
  md: boolean,
  lg: boolean,
  intl: Object
};

type State = {
  data: Object
};

class TravelCard extends Component<Props, State> {
  render() {
    const { data, to, showDriver, showDriverGroup, showStatus, user } = this.props;
    
    return (
      <MDBRow
        onClick={() => to ? history.push(to) : null}
        center
        className="TravelCardOperator pl-2 pr-1 pt-2 pb-2 d-flex justify-content-start position-relative z-depth-1"
      >
        <LeftDesignCard style={{ top: '20px', left: 0, height: '95px' }} />
        <Address data={data} />
        <DateAndTime data={data} />
        <DriverAndStatus
          data={data}
          showDriver={showDriver}
          showDriverGroup={showDriverGroup}
          showStatus={showStatus}
          user={user}
        />
      </MDBRow>
    );
  }
}

export default TravelCard;
