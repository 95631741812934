import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M12 7c-2.029 0-3.88.54-5.29 1.429H4s.61.768.897 1.635A4.341 4.341 0 0 0 4 12.714C4 15.082 5.868 17 8.174 17a4.11 4.11 0 0 0 3.07-1.39L12 16.642l.755-1.034A4.11 4.11 0 0 0 15.826 17C18.132 17 20 15.082 20 12.714a4.341 4.341 0 0 0-.897-2.65c.287-.867.897-1.635.897-1.635h-2.712C15.878 7.539 14.028 7 12 7zm0 .714c1.35 0 2.639.253 3.757.719-1.682.028-3.122 1.077-3.757 2.572-.636-1.498-2.079-2.549-3.766-2.572l.024-.009c1.117-.46 2.401-.71 3.742-.71zM8.174 9.143c1.921 0 3.478 1.599 3.478 3.571 0 1.973-1.557 3.572-3.478 3.572-1.921 0-3.478-1.6-3.478-3.572s1.557-3.571 3.478-3.571zm7.652 0c1.921 0 3.478 1.599 3.478 3.571 0 1.973-1.557 3.572-3.478 3.572-1.921 0-3.478-1.6-3.478-3.572s1.557-3.571 3.478-3.571zM8.174 10.57c-1.152 0-2.087.96-2.087 2.143 0 1.183.935 2.143 2.087 2.143 1.152 0 2.087-.96 2.087-2.143 0-1.183-.935-2.143-2.087-2.143zm7.652 0c-1.152 0-2.087.96-2.087 2.143 0 1.183.935 2.143 2.087 2.143 1.152 0 2.087-.96 2.087-2.143 0-1.183-.935-2.143-2.087-2.143zm-7.652.715c.769 0 1.391.639 1.391 1.428 0 .79-.622 1.429-1.391 1.429-.77 0-1.391-.64-1.391-1.429 0-.79.622-1.428 1.39-1.428zm7.652 0c.77 0 1.391.639 1.391 1.428 0 .79-.622 1.429-1.39 1.429-.77 0-1.392-.64-1.392-1.429 0-.79.622-1.428 1.391-1.428zM8.174 12a.705.705 0 0 0-.696.714c0 .395.311.715.696.715.384 0 .696-.32.696-.715A.705.705 0 0 0 8.174 12zm7.652 0a.705.705 0 0 0-.696.714c0 .395.312.715.696.715.385 0 .696-.32.696-.715a.705.705 0 0 0-.696-.714z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
