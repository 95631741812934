import { ADD_ERROR, REMOVE_ERROR } from '../actions/errors/types';

const errorsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_ERROR:
      return state.concat([action.values]);

    case REMOVE_ERROR:
      return [];

    default:
      return state;
  }
};

export default errorsReducer;
