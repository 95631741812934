import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M5.778 4A1.79 1.79 0 004 5.778v12.444A1.79 1.79 0 005.778 20h12.444A1.79 1.79 0 0020 18.222V5.778A1.79 1.79 0 0018.222 4H5.778zm0 1.778h12.444v4.965l-2.927-2.927-1.257 1.257 2.038 2.038H9.333v1.778h6.743l-2.038 2.038 1.257 1.257 2.927-2.927v4.965H5.778V5.778z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
