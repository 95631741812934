/**
 * Détermine la largeur de l'écran et donc le type d'affichage
 */

import React from 'react';
import _ from 'lodash';

import Breakpoints from './Dimensions';

const sm = () => window.innerWidth <= Breakpoints.sm;

const md = () => window.innerWidth <= Breakpoints.md;

const lg = () => window.innerWidth <= Breakpoints.lg;

const xl = () => window.innerWidth <= Breakpoints.xl;

/* eslint-disable */
const MobileContext = React.createContext({
  sm: sm(),
  md: md(),
  md: md(),
  xl: xl()
});

export class MobileProvider extends React.Component<
  MobileProviderProps,
  MobileProviderState
> {
  constructor(props) {
    super();
    this._handleWindowSizeChange = _.debounce(
      this._handleWindowSizeChange.bind(this),
      100
    );
    this.state = {
      sm: sm(),
      md: md(),
      lg: lg(),
      xl: xl()
    };
  }

  _handleWindowSizeChange = () => {
    this.setState({ md: md() });
    this.setState({ sm: sm() });
    this.setState({ lg: lg() });
    this.setState({ xl: xl() });
  };

  componentDidMount() {
    this._handleWindowSizeChange();
    window.addEventListener('resize', this._handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowSizeChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.state, nextState)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <MobileContext.Provider value={this.state}>
        {this.props.children}
      </MobileContext.Provider>
    );
  }
}

export function withMobileContext(Component: any) {
  return function MobileComponent(props: any) {
    return (
      <MobileContext.Consumer>
        {({ sm, md, lg, xl }) => (
          <Component {...props} md={md} sm={sm} lg={lg} xl={xl} />
        )}
      </MobileContext.Consumer>
    );
  };
}

export const MobileConsumer = MobileContext.Consumer;
