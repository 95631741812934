import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <rect id="BoundingBox"  fill={color || defaultColor} fillRule="nonzero" opacity="0" x="0" y="0" width="24" height="24"></rect>
        <path d="M11.1858736,4 C10.3310872,4 9.6394052,4.7156248 9.6394052,5.6 C9.6394052,6.4843752 10.3310872,7.2 11.1858736,7.2 C12.04066,7.2 12.732342,6.4843752 12.732342,5.6 C12.732342,4.7156248 12.04066,4 11.1858736,4 Z M11.1858736,8 C10.3341082,8 9.6394052,8.7187504 9.6394052,9.6 L9.6394052,13.6 C9.6394052,14.4812504 10.3341082,15.2 11.1858736,15.2 L14.6654275,15.2 L16.7434944,18.075 L18,17.125 L15.6802974,13.925 L15.4386617,13.6 L12.732342,13.6 L12.732342,9.6 C12.732342,8.7187504 12.0376398,8 11.1858736,8 Z M8.866171,10.4 C6.70051135,10.8812504 5,12.8 5,15.2 C5,17.8406248 7.08712845,20 9.6394052,20 C11.9591078,20 13.8197026,18.2406248 14.2063197,16 L12.6598513,16 C12.3517662,17.3593752 11.1073424,18.4 9.6394052,18.4 C7.93889386,18.4 6.5464684,16.9593752 6.5464684,15.2 C6.5464684,13.6812504 7.55227676,12.475 8.866171,12.075 L8.866171,10.4 Z" id="Shape" fill={color || defaultColor} fillRule="nonzero"></path>
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
