import { createMuiTheme } from '@material-ui/core';

/**
 * Theme Mui pour le DatePicker
 */
export function getMuiTheme() {
  return createMuiTheme({
    zIndex: {
      modal: 999999
    },
    overrides: {
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiInputBase: {
        input: {
          padding: 0
          // "padding-bottom": "0.5rem"
        }
      },
      MuiFormLabel: {
        root: {
          color: '#757575',
          borderColor: 'rgb(16, 21, 38)'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: 'rgb(16, 21, 38)'
        }
      },
      MuiPickersDay: {
        day: {
          color: 'rgb(16, 21, 38)',
          '&:hover': {
            color: 'rgb(16, 21, 38)'
          }
        },
        daySelected: {
          color: '#fceb26',
          backgroundColor: 'rgb(16, 21, 38)'
        },
        current: {
          color: '#fceb26'
        }
      },
      MuiTab: {
        root: {
          backgroundColor: 'rgb(16, 21, 38)'
        }
      },
      MuiButton: {
        label: {
          color: 'rgb(16, 21, 38)'
        }
      },
      MuiTypography: {
        root: {
          color: 'rgb(16, 21, 38)'
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: 'rgb(252, 235, 38)'
        }
      }
    }
  });
}
