import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    width,
    height,
  } = props;

  const defaultWidth=200;
  const defaultHeight=25;
  const defaultColor = '#0B060D';

  return (
    <svg width={width || defaultWidth} height={height || defaultHeight} viewBox="0 0 200 25" {...props}>
      <path className="st0" fill={color || defaultColor} d="M49.7,20.8v3.1c0,0.7-0.2,1.1-1.1,1.1h-17c-0.9,0-1.1-0.4-1.1-1.1V1.1c0-0.7,0.2-1.1,1.1-1.1H48
		c0.9,0,1.1,0.4,1.1,1.1v3.1c0,0.7-0.2,1.1-1.1,1.1H36.6v4.1h10.1c0.9,0,1.1,0.4,1.1,1.1v3c0,0.7-0.2,1.1-1.1,1.1H36.6v5h12.1
		C49.5,19.7,49.7,20.1,49.7,20.8"/>
	<path className="st0" fill={color || defaultColor} d="M74.7,17.1c0-1.8-1.3-2.3-3.1-2.3h-0.9c-0.9,0-1.1-0.4-1.1-1.1v-2.9c0-0.7,0.2-1.1,1.1-1.1h0.5
		c2.2,0,3.1-0.8,3.1-2.3c0-1.7-1.2-2.1-3.1-2.1h-5.8v14.2h6.4C73.7,19.6,74.7,18.8,74.7,17.1 M80.8,17.7c0,4.8-3.7,7.3-9,7.3H60.6
		c-0.9,0-1.1-0.4-1.1-1.1V1.1c0-0.7,0.2-1.1,1.1-1.1h10.6C77.4,0,80,3.3,80,6.9c0,3.8-2.4,5-2.8,5.2C77.6,12.2,80.8,13.6,80.8,17.7"
		/>
	<path className="st0" fill={color || defaultColor} d="M20.2,1.1v22.7c0,0.7-0.2,1.1-1.1,1.1h-3.4c-0.9,0-1.2-0.5-1.7-1.3l-8-13v13.2c0,0.9-0.4,1.1-1.1,1.1H1.1
		C0.4,25,0,24.7,0,23.9V1.1C0,0.5,0.2,0,1.1,0h3.6c0.9,0,1.2,0.5,1.7,1.3l8,13V1.1c0-0.9,0.4-1.1,1.1-1.1h3.6
		C19.8,0,20.2,0.3,20.2,1.1"/>
	<path className="st0" fill={color || defaultColor} d="M140.4,20.8v3.1c0,0.7-0.2,1.1-1.1,1.1h-15.8c-0.9,0-1.1-0.4-1.1-1.1V1.1c0-0.9,0.4-1.1,1.1-1.1h3.7
		c0.7,0,1.1,0.2,1.1,1.1v18.5h10.9C140.2,19.7,140.4,20.1,140.4,20.8"/>
	<path className="st0" fill={color || defaultColor} d="M168.9,20.8v3.1c0,0.7-0.2,1.1-1.1,1.1h-17c-0.9,0-1.1-0.4-1.1-1.1V1.1c0-0.7,0.2-1.1,1.1-1.1h16.3
		c0.9,0,1.1,0.4,1.1,1.1v3.1c0,0.7-0.2,1.1-1.1,1.1h-11.4v4.1h10.1c0.9,0,1.1,0.4,1.1,1.1v3c0,0.7-0.2,1.1-1.1,1.1h-10.1v5h12.1
		C168.6,19.7,168.9,20.1,168.9,20.8"/>
	<path className="st0" fill={color || defaultColor} d="M111.3,1.1v16.2c0,5.9-5.5,8.2-10,8.2c-4.5,0-10-2.2-10-8.2V1.1c0-0.9,0.4-1.1,1.1-1.1h3.7
		c0.7,0,1.1,0.2,1.1,1.1v15.4c0,2.4,1.6,3.7,4.1,3.7c2.5,0,4.1-1.4,4.1-3.7V1.1c0-0.9,0.4-1.1,1.1-1.1h3.7
		C110.9,0,111.3,0.3,111.3,1.1"/>
	<path className="st0" fill={color || defaultColor} d="M188.2,7.2l-2.8,8.4h5.7L188.2,7.2z M199.3,25h-4c-0.8,0-1.1-0.2-1.4-1.1l-1.1-3h-9.3l-1.1,3
		c-0.3,0.9-0.6,1.1-1.4,1.1h-4c-0.8,0-0.8-0.6-0.6-1.1l8.4-22.7c0.3-0.8,0.7-1.2,1.6-1.2h3.6c0.8,0,1.2,0.4,1.6,1.2l8.4,22.7
		C200.1,24.3,200.1,25,199.3,25"/>
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
