/**
 * Copie la chaine de caractères dans le presse-papier
 * @param {string} str Chaine à copier
 */
export default function clipboard(str: string, toastManager: Object, message: string) {
  if (getMobileOperatingSystem() === 'Android') {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ clipboard: str }));
      toastManager.add(message ? message : 'Copié !', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
        autoDismissTimeout: 1500
      });
    } else {
      setTimeout(async () => await navigator.clipboard.writeText(str).then(() => {
        toastManager.add(message ? message : 'Copié !', {
          appearance: 'success',
          autoDismiss: true,
          pauseOnHover: false,
          autoDismissTimeout: 1500
        });
      }).catch((e) => {
        console.log(e)
        toastManager.add('Erreur de copiage...', {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: false,
          autoDismissTimeout: 1500
        });
      }))
    }
  } else {
    setTimeout(async () => await navigator.clipboard.writeText(str).then(() => {
      toastManager.add(message ? message : 'Copié !', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
        autoDismissTimeout: 1500
      });
    }).catch((e) => {
      console.log(e);
      toastManager.add('Erreur de copiage...', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
        autoDismissTimeout: 1500
      });
    }))
  }
}

//Récupère l'OS du smartphone de l'utilisateur
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}
