// @flow

import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';


import { IconPassenger } from '../../../../../assets';
import ServiceIcon from '../../ServiceIcon';

import moment from 'moment';
import 'moment/locale/fr';

type Props = {
  data: Object,
  intl: Object
};

class DateAndTime extends PureComponent<Props> {
  render() {
    const { data, intl } = this.props;
    return (
      <div className="d-flex flex-column mt-3" style={{ width: '40%' }}>
        <p className="d-flex flex-column align-items-end mb-2">
          <span className="TextTravelCard">{moment(data.departure_date).format('ddd DD/MM/YY')}</span>
          <span className="TitleTravelCard">{moment(data.departure_date).format('HH[h]mm')} {data.catalogue && data.catalogue.basis === 'HOURLY' && data.catalogue.quantity > 0 ? ' - ' + moment(data.departure_date).add(data.catalogue.quantity_basis * data.catalogue.quantity, 'hours').format('HH[h]mm') : ''}</span>
          <span>
            {intl.formatMessage({ id: 'passenger.ride.details.details.passenger' }, { count: data.nb_passenger })}
            <span>
              <IconPassenger />
            </span>
          </span>
          <span>
            {(data.passenger.name && data.passenger.name.length > 13) || ((data.passenger.name ? data.passenger.name.length : 0) + (data.passenger.first_name ? data.passenger.first_name.length : 0) > 12) ? (data.passenger.name ? (data.passenger.name.substring(0, 14) || '') : data.passenger.first_name.substring(0, 14)) : (data.passenger.first_name || '') + " " + (data.passenger.name || '')}
            <span className='pl-1'>{ServiceIcon[data.service.toLowerCase()]}</span>
          </span>
        </p>
      </div>
    );
  }
}

export default injectIntl(DateAndTime);
