import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { IntlProvider } from 'react-intl';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';

import messagesEn from './translations/en.json';
import messagesFr from './translations/fr.json';
import messagesNl from './translations/nl.json';
import messagesEs from './translations/es.json';

// Sentry
import * as Sentry from '@sentry/react';
import reducers from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';

import './assets/scss/mdb.scss';

// Context -------------------------------------
import { MobileProvider } from './modules/MobileContext';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
const store = createStore(reducers, enhancer);
// const store = createStore(reducers, applyMiddleware(thunkMiddleware));

const messages = {
  fr: messagesFr,
  en: messagesEn,
  nl: messagesNl,
  es: messagesEs
};

let language = navigator.language.split(/[-_]/)[0]; // language without region code
if (messages[language] === undefined) {
  language = 'en'; // default
}

//var myScreenOrientation = window.screen.orientation;
//myScreenOrientation.lock("portrait");

Sentry.init({
  dsn: 'https://115ea40f9aab4364b364f2b936621cbd@sentry.io/1724072',
  ignoreErrors: [
    'Network Error',
    'timeout of 0ms exceeded',
    'Request failed with status code 401',
    'Timeout of 2000ms exceeded calling create on authentication',
    'Timeout of 5000ms exceeded calling create on authentication',
    'Non-Error promise rejection captured'
  ],
  debug: true
});

render(
  <Provider store={store}>
    <MobileProvider>
      <IntlProvider locale={language} messages={messages[language]}>
        <App />
      </IntlProvider>
    </MobileProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
