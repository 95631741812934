import { TRANSFER_RIDE } from './types';
/**
 * Modifie le Ride à transférer
 * @param {String} ride Ride
 */
export function transferRide(ride: String) {
  return (dispatch: Function) => {
    dispatch({
      type: TRANSFER_RIDE,
      values: ride
    });
  };
}
