import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M15 17.776C15 19.005 13.881 20 12.5 20s-2.5-.995-2.5-2.224c0-1.228 1.119-2.224 2.5-2.224s2.5.996 2.5 2.224zm-3.94 0c0 .707.646 1.28 1.44 1.28.794 0 1.44-.574 1.44-1.28 0-.706-.646-1.28-1.44-1.28-.794 0-1.44.574-1.44 1.28zM15 6.224c0 1.228-1.119 2.224-2.5 2.224S10 7.452 10 6.224C10 4.995 11.119 4 12.5 4s2.5.995 2.5 2.224zm-3.94 0c0 .706.646 1.28 1.44 1.28.794 0 1.44-.574 1.44-1.28 0-.706-.646-1.28-1.44-1.28-.794 0-1.44.574-1.44 1.28zM15 12c0 1.228-1.119 2.224-2.5 2.224S10 13.23 10 12c0-1.228 1.119-2.224 2.5-2.224S15 10.772 15 12zm-3.94 0c0 .706.646 1.28 1.44 1.28.794 0 1.44-.574 1.44-1.28 0-.706-.646-1.28-1.44-1.28-.794 0-1.44.574-1.44 1.28z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
