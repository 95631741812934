// @flow

import React, { Fragment } from 'react';
import { apiUrl } from '../../../const';

import { withMobileContext } from '../../../modules/MobileContext';
import { injectIntl } from 'react-intl';
import SubHeader from './SubHeader';

type Props = {
  dg: Object,
  bg: Object,
  sm: boolean,
  md: boolean,
  intl: Object
};

class DriverGroupHeader extends React.PureComponent<Props> {
  render() {
    const { dg, bg, intl, displayHeaderFooter } = this.props;
    return (
      <Fragment>
        {/* {displayHeaderFooter && <Header
          {...this.props}
          transparent={false}
          logo={sm || md ? 'logoWhite' : 'logo'}
        />} */}
        <div className="Brand">
          {displayHeaderFooter && <div className="Logo">
            {dg.logo ? (
              <img src={`${apiUrl}images/${dg.logo}`} alt={dg.brand} />
            ) : null}
          </div>}
          <SubHeader dg={dg} bg={bg} />

        </div>
        <div className="Nav navbar navbar-expand-lg text-uppercase position-static shadow">
          <div className="navbar-nav">
            <a className="nav-item nav-link active" href="#description">
              {intl.formatMessage({ id: 'driver-group.titles.description' })}
            </a>
            <a className="nav-item nav-link" href="#services">
              {intl.formatMessage({ id: 'driver-group.nav.services' })}
            </a>
            <a className="nav-item nav-link" href="#follow-us">
              {intl.formatMessage({ id: 'driver-group.nav.follow-us' })}
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withMobileContext(injectIntl(DriverGroupHeader));
