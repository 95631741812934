import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M5.6 7C4.721 7 4 7.689 4 8.528v7.944C4 17.312 4.721 18 5.6 18h12.8c.879 0 1.6-.689 1.6-1.528V8.528C20 7.688 19.279 7 18.4 7H5.6zm0 .611h12.8c.53 0 .96.41.96.917v7.944c0 .506-.43.917-.96.917H5.6c-.53 0-.96-.41-.96-.917V8.528c0-.506.43-.917.96-.917zm1.02 2.96l1.6 4.373h.71l1.6-4.373h-.64l-1.31 3.791-1.32-3.79h-.64zm5.09 0v4.373h.61v-4.373h-.61zm2.24 0v4.373h.61V13.14h1.41c.48 0 .826-.12 1.05-.334.224-.214.36-.548.36-.946 0-.397-.136-.731-.36-.945-.256-.214-.602-.344-1.05-.344h-2.02zm.64.497h1.19c.353 0 .568.088.76.21.193.122.26.308.26.582 0 .275-.1.459-.26.611-.16.153-.407.21-.76.21v-.028h-1.19v-1.585z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
