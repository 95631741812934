import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={50} fill="none" {...props}>
      <path
        d="M12.012.889L12 1v3l.219.414.465.05.304-.353L13 4V1l-.219-.414-.465-.05zm0 6L12 7v3l.219.414.465.05.304-.353L13 10V7l-.219-.414-.465-.05zm0 6L12 13v3l.219.414.465.05.304-.353L13 16v-3l-.219-.414-.465-.05zm0 6L12 19v3l.219.414.465.05.304-.353L13 22v-3l-.219-.414-.465-.05zm0 6L12 25v3l.219.414.465.05.304-.353L13 28v-3l-.219-.414-.465-.05zm0 6L12 31v3l.219.414.465.05.304-.353L13 34v-3l-.219-.414-.465-.05zm0 6L12 37v3l.219.414.465.05.304-.353L13 40v-3l-.219-.414-.465-.05zm0 6L12 43v3l.219.414.465.05.304-.353L13 46v-3l-.219-.414-.465-.05zm0 6L12 49v3l.219.414.465.05.304-.353L13 52v-3l-.219-.414-.465-.05zm0 6L12 55v2l.219.414.465.05.304-.353L13 57v-2l-.219-.414-.465-.05z"
        fill={color || defaultColor}
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
