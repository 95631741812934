import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M11.525 11.177v-3.93c0-.269.206-.475.475-.475.27 0 .475.206.475.476v3.929a.955.955 0 0 1 .348.348h2.345c.27 0 .476.206.476.475 0 .27-.206.475-.476.475h-2.345a.95.95 0 1 1-1.298-1.298zM12 20c-4.404 0-8-3.596-8-8s3.596-8 8-8 8 3.596 8 8-3.596 8-8 8zm0-15.05C8.119 4.95 4.95 8.12 4.95 12S8.12 19.05 12 19.05s7.05-3.169 7.05-7.05S15.88 4.95 12 4.95z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
