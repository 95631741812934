import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M18.99 19.47c.002.016.01.03.01.046 0 .267-.22.483-.492.483a.487.487 0 0 1-.493-.483h-.01v-2.32c0-2.209-1.832-4.005-4.084-4.005H11.08c-2.252 0-4.086 1.796-4.086 4.005v2.321h-.008c0 .267-.22.483-.493.483A.487.487 0 0 1 6 19.517c0-.016.008-.03.01-.046v-2.275c0-2.741 2.274-4.971 5.069-4.971h2.842c2.795 0 5.07 2.23 5.07 4.971v2.275zm-6.49-7.782c-2.162 0-3.92-1.724-3.92-3.844S10.338 4 12.5 4c2.16 0 3.92 1.725 3.92 3.844 0 2.12-1.758 3.844-3.92 3.844zm0-6.722c-1.619 0-2.936 1.292-2.936 2.878 0 1.587 1.317 2.878 2.936 2.878 1.618 0 2.935-1.29 2.935-2.878 0-1.587-1.317-2.878-2.935-2.878z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
