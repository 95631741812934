// @flow

import React, { PureComponent } from 'react';

type Props = {
  content: string,
  isVisible: boolean
};

class ErrorMessage extends PureComponent<Props> {
  render() {
    const { content, isVisible } = this.props;

    return (
      <div>
        {isVisible ? <p className="text-danger">{content}</p> : null}
      </div>
    );
  }
}
export default ErrorMessage;
