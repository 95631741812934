import { ADD_ERROR, REMOVE_ERROR } from './types';

export function addError() {
  return (dispatch) => {
    dispatch({
      type: ADD_ERROR,
    });
  };
}

export function removeError(index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ERROR,
      values: index,
    });
  };
}
