import API from '../../../components/Api';

/**
 * Converti la clé Base 64 en UInt8
 * @param {string} base64String
 */
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  try {
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  } catch (error) {
    return null;
  }
}

/**
 * Envoie la requete de souscription à l'API
 * @param {Object} subscription
 * @param {Object} user
 */
function sendSubscription(subscription: Object, user: Object) {
  API.patch(`users/${user._id}`, {
    webpush_subscription: JSON.stringify(subscription)
  });
}

const publicVapidKey = 'BMUJgTHaCOCHgAMhssw9LM_x8M4mW117i8xMCSzjCNVP42XS0zVAjc-ahmoK6eK8GOw2BZexoqIo_8dxasvEjB8';
const convertedVapidKey = urlBase64ToUint8Array(publicVapidKey);

/**
 * Souscrit l'utilisateur aux Webpush
 * @param {Object} user
 */
export function subscribeUser(user: Object) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        if (!registration.pushManager) {
          // Push manager unavailable
          return;
        }

        registration.pushManager.getSubscription().then((existedSubscription) => {
          if (existedSubscription === null) {
            // No subscription detected, make a request
            registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true
              })
              .then((newSubscription) => {
                // New subscription added
                sendSubscription(newSubscription, user);
              })
              .catch(() => {
                if (Notification.permission !== 'granted') {
                  // Permission was not granted
                } else {
                  // An error ocurred during the subscription process
                }
              });
          } else {
            // Existed subscription detected
            sendSubscription(existedSubscription, user);
          }
        });
      })
      .catch(() => {
        // An error ocurred during Service Worker registration
      });
  }
}
