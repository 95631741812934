import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M12 4c-4.414 0-8 3.586-8 8s3.586 8 8 8 8-3.586 8-8-3.586-8-8-8zM9.84 5.13c-.362.724-.64 1.665-.64 1.92 0 .432.338.55.95.55.64 0 .65.492.65.6v1.4c0 .65-.45.8-.8.8-1.152 0-1.2.768-1.2 1.2 0 .15-.026.4-.35.4-.6 0-.16-.4-.7-.4H7.5c-.3 0-.3.232-.3.4 0 .4.4 1.6 1.6 1.6H9.7c.252 0 .512.036.75.25.5.45.568.55 1 .55.4 0 .55.406.55.55 0 .75-.408 1.168-.641 1.346a.767.767 0 0 0-.248.341c-.181.475-.568 1.505-.722 2.376a7.14 7.14 0 0 1-1.589-.568V17.8c0-.72-.8-1.676-.8-2.9 0-.72-1.012-1.384-1.3-1.6-.4-.3-.747-.647-1.034-1.174a.825.825 0 0 0-.17-.219l-.119-.105a.801.801 0 0 0-.53-.202H4.82a7.19 7.19 0 0 1 5.02-6.47zm6.325 1.001A7.182 7.182 0 0 1 19.2 12a7.18 7.18 0 0 1-2.99 5.834c-.179-.39-.432-.939-.538-1.19a47.607 47.607 0 0 0-.3-.703.777.777 0 0 0-.452-.42c-.453-.158-1.32-.588-1.32-1.521 0-.396.15-1.2 1.6-1.2 1.08 0 2-.84 2-1.2 0-.647-.23-1.143-.373-1.397a.392.392 0 0 0-.464-.176 2.61 2.61 0 0 0-.662.323c-.65.45-.585 1.25-1.701 1.25-.144 0-.4-.748-.4-1 0-.2.308-.279.6-.4.206-.086.2-.2.2-.4 0-.138-.274-.334-.35-.45A.287.287 0 0 1 14 9.2a.305.305 0 0 1 .122-.242l.828-.508c.313-.195.946-1.669 1.215-2.319z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
