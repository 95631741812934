import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17.285 4c-.689 0-1.378.267-1.912.802L4.967 15.208l-.042.206-.72 3.62L4 20l.967-.206 3.62-.72.205-.04L19.198 8.626a2.681 2.681 0 000-3.825A2.696 2.696 0 0017.285 4zm0 1.254c.332 0 .666.152.987.474.64.64.64 1.334 0 1.974l-.473.452-1.953-1.953.452-.473c.322-.322.656-.474.987-.474zM14.92 7.126l1.954 1.954-7.959 7.959a4.484 4.484 0 00-1.954-1.954l7.96-7.959zm-8.802 9.028c.79.319 1.409.939 1.728 1.728l-2.16.432.432-2.16z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
