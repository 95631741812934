import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import { apiUrl } from './const';

const url = new URL(apiUrl);
const socketClient = io(url.origin, { path: url.pathname + 'socket.io' });
const socket = feathers();
socket.configure(
  socketio(socketClient, {
    timeout: 2000
  })
);

try {
  socket.configure(
    auth({
      storageKey: 'nebulea_token'
    })
  );
} catch { }

try {
  if (localStorage) {
    socketClient.emit('create', 'authentication', {
      strategy: 'jwt',
      accessToken: localStorage.getItem('nebulea_token')
    });
  }
} catch { }

// if (localStorage.getItem('nebulea_token')) {
//   client.reAuthenticate();
// }

export { socket, socketClient };