import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    background,
  } = props;

  return (
    <svg width={24} height={24} {...props}>
      <circle
        stroke={color}
        fill={color}
        fillRule="nonzero"
        cx={13}
        cy={13}
        r={5}
        strokeWidth="2"
      />
      <path
        d="M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm2.435-10.872a.327.327 0 0 0-.224.141l-2.931 4.113-1.382-1.289c-.12-.17-.348-.183-.467-.07l-.542.512c-.119.17-.119.399 0 .511l2.092 1.977c.119.058.287.177.467.177.119 0 .31-.06.43-.23l3.585-4.977c.119-.17.05-.329-.187-.442l-.598-.406a.456.456 0 0 0-.243-.017z"
        fill={background}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#FFFFFF',
  background: '#0B060D',
};

export default SvgComponent;
