/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { SET_USER, SET_DRIVER_GROUP } from './types';
import { IS_FETCHING, IS_NOT_FETCHING } from '../loading/types';
import { ADD_ERROR, REMOVE_ERROR } from '../errors/types';
import API, { setAuthorization } from '../../components/Api';

import {socket} from '../../socket';

import * as hooks from './hooks';
const { getUserRoles, redirectLoggedUser, subscribeUser } = hooks;

type SignIn = {
  strategy: string,
  password: string,
  remember: boolean,
  email?: string,
  phone?: string
};

/**
 * Authentifie l'utilisateur
 * @param {SignIn} data Informations de connexion
 * @param {Object} history Historique de navigation
 * @param {Object} mobileContext Largeurs d'écran acceptées
 */
export function setUser(data: SignIn, history: Object, mobileContext: Object) {
  return (dispatch: Function) => {
    dispatch({ type: IS_FETCHING });
    dispatch({ type: REMOVE_ERROR });

    API.post('authentication', data)
      .then((res) => {
        const { accessToken, user } = res.data;

        // Authorization API
        setAuthorization(accessToken);

        // Souscription Web Push
        subscribeUser(user);
        // Envoi des données à l'app native
        // pour les notifications Push chauffeur et
        const userId = { _id: user._id };
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(userId));
        }

        // Sauvegarde des infos du user
        user.roles = getUserRoles(user);
        dispatch({
          type: SET_USER,
          values: user
        });
        // S'il y a plusieurs DG, on affecte le premier par défault
        if (user.driver_groups.length > 0) {
          const dg_manager = user.driver_groups.filter((item) => item.role === 'MANAGER');
          let dg_id = "";
          if (dg_manager.length > 0) {
            dg_id = dg_manager[0]._id;
          } else {
            dg_id = user.driver_groups[0]._id;
          }
          API.get(`driver-groups/${dg_id}`).then((resDG) => {
            dispatch({
              type: SET_DRIVER_GROUP,
              values: resDG.data
            });
          });
        }
        // Réauthentification future
        if (localStorage && (data.remember || localStorage.getItem('nebulea_remember'))) {
          localStorage.setItem('nebulea_token', accessToken);
          if (data.remember) {
            localStorage.setItem('nebulea_remember', true);
          }
        }
        sessionStorage.setItem('nebulea_token', accessToken);

        socket.authenticate(data).catch((error) => {
          // console.log(error);
        });

        dispatch({ type: REMOVE_ERROR });

        if (!window.location.pathname.includes('onboarding') && !window.location.pathname.includes('subscription') && !window.location.pathname.includes('frame') && !window.location.pathname.includes('dg/') && !window.location.pathname.includes('creation')) {
          // On redirige l'utilisateur vers son interface
          redirectLoggedUser(user, history, mobileContext);
        }
      })
      .catch((err) => {
        // ErrorManager.Instance.Handle(err);
        if (data.strategy !== 'jwt') {
          dispatch({
            type: ADD_ERROR,
            values: err.response ? err.response.data : err
          });
        }
      })
      .then(() => {
        dispatch({ type: IS_NOT_FETCHING });
      });
  };
}
