import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M20 7.539a6.567 6.567 0 0 1-1.887.517 3.29 3.29 0 0 0 1.444-1.815 6.592 6.592 0 0 1-2.085.795 3.282 3.282 0 0 0-5.593 2.993A9.321 9.321 0 0 1 5.115 6.6 3.269 3.269 0 0 0 4.67 8.25c0 1.139.578 2.144 1.46 2.733a3.28 3.28 0 0 1-1.488-.411v.041a3.287 3.287 0 0 0 2.633 3.22 3.285 3.285 0 0 1-1.483.054 3.29 3.29 0 0 0 3.066 2.28A6.581 6.581 0 0 1 4 17.525 9.276 9.276 0 0 0 9.032 19c6.037 0 9.34-5 9.34-9.337 0-.142-.004-.286-.01-.425A6.672 6.672 0 0 0 20 7.538z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
