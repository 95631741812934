// @flow
import React from 'react';
import { MDBBtn } from 'mdbreact';
import { PrepaymentRequired } from '../../../../const';

type Props = {
  intl: Object,
  isFetching: Boolean,
  createRide: Function
};

class PriceAndCTA extends React.PureComponent<Props, State> {

  render() {
    const { intl, isFetching, createRide, cgv, dg, showConfirmationMessage, invited, booking } = this.props;

    return (
      <div className="PriceAndCTA">
        <p style={{ fontSize: '0.9rem' }}>
          {intl.formatMessage({ id: 'driver-group.booking.form.cgu.accept' })}
          {' '}
          <a href="https://nebulea.eu/assets/homePage/ConditionsGeneralesDeVente_Nebulea.pdf" target="_blank" style={{ textDecoration: 'underline' }}>{intl.formatMessage({ id: 'driver-group.booking.form.cgu.link' })}</a>
          {cgv ? " " + intl.formatMessage({ id: 'driver-group.booking.form.cgv.accept' }) : ''}
          {cgv ? <a href={cgv} target="_blank" style={{ textDecoration: 'underline !important' }}> {intl.formatMessage({ id: 'driver-group.booking.form.cgv.link' })}</a> : null}
          {'.'}
        </p>
        <div className="ButtonsBloc">
          <MDBBtn
            color="secondary"
            className="btn-rectangular"
            onClick={isFetching ? null : () => createRide('PROCESSING')}
            onKeyPress={() => { }}
            role="button"
            tabIndex="0"
          >
            {dg.online_payment_id && !invited && PrepaymentRequired.includes(dg._id) ? intl.formatMessage({
              id: 'driver-group.booking.form.buttons.prepay'
            }) : intl.formatMessage({
              id: 'driver-group.booking.form.buttons.book-now'
            })}
          </MDBBtn>
          {showConfirmationMessage ? <div>
            <p style={{ color: 'red', textAlign: 'center' }}>Pour confirmer votre réservation, il faut que vous pré-payiez en ligne, sans quoi, aucun chauffeur ne viendra.</p>
          </div> : null}
          
          {booking.price_client ? null : <MDBBtn
            outline={true}
            color="secondary"
            className="btn-rectangular"
            onClick={isFetching ? null : () => createRide('QUOTE')}
            onKeyPress={() => { }}
            role="button"
            tabIndex="0"
          >
            {intl.formatMessage({
              id: 'driver-group.booking.form.buttons.request-quotation'
            })}
          </MDBBtn>}
        </div>
      </div>
    );
  }
}

export default PriceAndCTA;
