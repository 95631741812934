import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    size
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <path
        d="M11.846 15.463c.096 0 .154-.055.155-.078 0-.024-.059-.078-.155-.078h-.31a.743.743 0 01-.773-.708.727.727 0 01.619-.69v-.014c0-.173.138-.314.309-.314.17 0 .309.14.309.314h.31c.17 0 .308.14.308.313a.311.311 0 01-.309.314h-.773c-.097 0-.155.055-.155.078 0 .024.058.078.155.078h.309a.743.743 0 01.773.708.727.727 0 01-.618.69v.014a.311.311 0 01-.31.314c-.17 0-.308-.14-.308-.314h-.31c-.17 0-.309-.14-.309-.313s.139-.314.31-.314h.773zm-4.853-2.71l-1.225 1.211v2.039l1.109 1.23h2.339c.147 0 .265.12.265.268a.267.267 0 01-.265.269H6.76a.265.265 0 01-.196-.087l-1.257-1.394a.27.27 0 01-.07-.181v-2.231a.34.34 0 01.08-.22L6.7 12.291a.265.265 0 01.186-.076h2.331c.146 0 .265.12.265.269a.267.267 0 01-.265.269H6.993zm4.698 5c-1.503-.001-2.721-1.236-2.723-2.76 0-1.524 1.22-2.76 2.723-2.76s2.722 1.236 2.722 2.76c0 1.524-1.219 2.76-2.722 2.76zm0-5.019c-1.23.002-2.226 1.012-2.228 2.259 0 1.247.998 2.258 2.228 2.258 1.23 0 2.227-1.011 2.227-2.258 0-1.248-.997-2.259-2.227-2.259zm5.196-.25a.267.267 0 01-.266.269h-2.456a.267.267 0 01-.265-.27c0-.147.119-.268.265-.268h2.456c.146 0 .266.12.266.269zm-8.73-5.715a.271.271 0 01-.108-.365l.619-1.255a.265.265 0 01.34-.128l7.426 3.136a.27.27 0 01.143.352.264.264 0 01-.347.145L9.033 5.615l-.507 1.03v-.001a.265.265 0 01-.368.125zm-3.33 3.468a.268.268 0 01-.201-.321l.618-2.823a.264.264 0 01.317-.203l12.377 2.823c.12.028.207.136.207.261a.267.267 0 01-.324.263L5.704 7.473l-.56 2.56a.266.266 0 01-.317.204zm14.909.723c.146 0 .265.121.264.268v.942c0 .148-.12.268-.265.268a.267.267 0 01-.265-.27v-.672H4.53v6.99h10.254c.025 0 .046.009.069.015a1.216 1.216 0 011.18-.959c.67 0 1.213.55 1.213 1.23A1.22 1.22 0 0116.034 20a1.216 1.216 0 01-1.188-.988c-.02.006-.04.013-.061.013H4.265A.267.267 0 014 18.757v-7.528c0-.149.12-.269.265-.269h15.47zm-3.702 7.104a.703.703 0 00-.697.708c0 .39.313.707.697.707a.703.703 0 00.698-.707.703.703 0 00-.698-.708z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
  size: 24,
};

export default SvgComponent;
