// @flow

import React, { Fragment } from 'react';

// Traduction
import { injectIntl } from 'react-intl';

import PassengerMode from './PassengerMode';
import Time from './Time';
import Address from './Address';
import { MDBCol, MDBRow } from 'mdbreact';

type Props = {
  intl: Object,
  dg: Object,
  booking: Object,
  activeTab: String,
  setPoint: Function,
  setService: Function,
  handleBookingInputChange: Function,
  increasePassenger: Function
};

class AddressFrame extends React.PureComponent<Props, State> {
  render() {
    const {
      intl,
      dg,
      booking,
      activeTab,
      setPoint,
      setService,
      handleBookingInputChange,
      increasePassenger,
      vertical,
      errors,
      estimatePrice,
      quotation,
      invited
    } = this.props;

    if (vertical) {
      return (
        <Fragment>
          <Address
            intl={intl}
            dg={dg}
            booking={booking}
            setPoint={setPoint}
            handleBookingInputChange={handleBookingInputChange}
            errors={errors}
          />
          <Time
            intl={intl}
            dg={dg}
            booking={booking}
            activeTab={activeTab}
            handleBookingInputChange={handleBookingInputChange}
            estimatePrice={estimatePrice}
            quotation={quotation}
            invited={invited}
          />
          <PassengerMode
            intl={intl}
            dg={dg}
            booking={booking}
            activeTab={activeTab}
            setPoint={setPoint}
            setService={setService}
            handleBookingInputChange={handleBookingInputChange}
            increasePassenger={increasePassenger}
          />
        </Fragment>
      );
    } else {
      return (
        <MDBRow>
          <MDBCol size="6">
            <Address
              intl={intl}
              dg={dg}
              booking={booking}
              setPoint={setPoint}
              handleBookingInputChange={handleBookingInputChange}
              errors={errors}
            />
            <Time
              intl={intl}
              dg={dg}
              booking={booking}
              activeTab={activeTab}
              handleBookingInputChange={handleBookingInputChange}
              quotation={quotation}
            />
          </MDBCol>

          <MDBCol size="6">
            <PassengerMode
              intl={intl}
              dg={dg}
              booking={booking}
              activeTab={activeTab}
              setPoint={setPoint}
              setService={setService}
              handleBookingInputChange={handleBookingInputChange}
              increasePassenger={increasePassenger}
            />
          </MDBCol>
        </MDBRow>
      );
    }
  }
}

export default injectIntl(AddressFrame);
