import Api from "../../components/Api";
import { shortenAddress } from '../../helpers/address.js';

/**
 * Retourne un champ texte des infos de la course
 * @param {Object} ride Course
 */
export default async function rideToString(ride: Object) {
    let string = '';

    if (!ride.departure_date) {
        const data = await Api.get(`rides/${ride._id}`);
        ride = data.data;
    }

    string = 'Départ : ' + ride.departure_address + '\nArrivée : ' + ride.arrival_address;

    const stops = await Api.get(`stops/?ride=${ride._id}&$limit=-1`);
    if (stops.data.length > 0) {
        const newPassengers = [];
        newPassengers.push(ride.passenger);
        const newStops = [];
        stops.data.map(stop => {
            stop.passengers_in.map(user => {
                if (!newPassengers.find(u => u._id === user._id)) {
                    newPassengers.push(user);
                }
            })
            stop.passengers_out.map(user => {
                if (!newPassengers.find(u => u._id === user._id)) {
                    newPassengers.push(user);
                }
            })
            if (stop.address) {
                newStops.push(shortenAddress(stop.address));
            }
        })
        if (newPassengers.length > 0) {
            string += '\nPassager' + (newPassengers.length === 1 ? '' : 's') + ' : ';
            for (let i = 0; i < newPassengers.length; i++) {
                string += (newPassengers[i].first_name ? (newPassengers[i].first_name + ' ') : '') + newPassengers[i].name + (i === newPassengers.length - 1 ? '' : ' & ')
            }
        }
        if (newStops.length > 0) {
            string += '\nArrêt' + (newStops.length === 1 ? '' : 's') + ' : ';
            for (let i = 0; i < newStops.length; i++) {
                string += (i + 1) + '° ' + newStops[i] + (i === newStops.length - 1 ? '' : ' - ');
            }
        }
    } else {
        string += '\nPassager : ' + (ride.passenger ? (ride.passenger.first_name + ' ' + ride.passenger.name) : '');
    }

    if (ride.code_promo) {
        string += '\n#' + ride.code_promo;
    }

    if (ride.payment_method_type) {
        string += '\nPayé en ' + ride.payment_method_type;
    }


    return string;
}