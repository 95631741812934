import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M19.033 4c-1.209 0-3.271 1.445-3.696 1.897l-2.169 2.167-7.713-1.043a.323.323 0 0 0-.271.1L4.089 8.245a.317.317 0 0 0-.08.29c.023.106.09.193.19.232l5.946 2.328-3.063 3.12H5.606a.327.327 0 0 0-.211.081L4.109 15.38a.324.324 0 0 0-.1.321.32.32 0 0 0 .231.24l3.063.754.753 3.06a.33.33 0 0 0 .221.231.31.31 0 0 0 .302-.07l1.094-.963a.325.325 0 0 0 .11-.241v-1.787l3.154-3.07 2.3 5.94c.039.1.126.169.231.191a.34.34 0 0 0 .07.01c.083 0 .161-.031.221-.09l1.125-1.094a.322.322 0 0 0 .1-.27l-1.044-7.708 2.17-2.168c.444-.444 2.623-3.385 1.636-4.415-.174-.167-.415-.251-.713-.251z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
