/**
 * Redirige l'utilisateur vers l'interface qui lui correspond
 * @param {Object} user Utilisateur courant
 * @param {Object} history Historique de navigation
 * @param {Object} mobileContext Largeurs d'écran acceptées
 */
export function redirectLoggedUser(user: Object, history: Object, mobileContext: Object) {
  const { sm, md, lg } = mobileContext;

  if (user.roles.includes('business')) {
    history.push('/business/rides');
    return;
  }
  if (user.roles.includes('manager')) {
    if (sm && md && lg) {
      history.push('/home');
      return;
    }
    history.push('/manager/dashboard');
    return;
  }
  if (user.roles.includes('driver') || user.roles.includes('extern')) {
    // if (sm && md && lg) {
    history.push('/operator');
    return;
    // }
  }

  if (user.roles.includes('passenger') && user._id) {
    // history.push('/passenger/rides');
    return;
  }
  if (user.roles.includes('admin')) {
    history.push('/admin');
  }

  // history.push('/');
}
