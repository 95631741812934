import moment from "moment";
import Api from "../../components/Api";
import { shortenAddress } from '../../helpers/address.js';

/**
 * Retourne un champ texte des infos de la course
 * @param {Object} ride Course
 */
export default async function rideToOrder(rideId: String, isClient: Boolean) {
    const data = await Api.get(`rides/${rideId}`);
    const ride = data.data;
    const stops = await Api.get(`stops/?ride=${rideId}&$limit=-1`);
    let stopsText = "";
    let passengersText = "";

    if (stops.data.length > 0) {
        const newPassengers = [];
        const newStops = [];
        stops.data.map(stop => {
            stop.passengers_in.map(user => {
                if (!newPassengers.find(u => u._id === user._id)) {
                    newPassengers.push(user);
                }
            })
            stop.passengers_out.map(user => {
                if (!newPassengers.find(u => u._id === user._id)) {
                    newPassengers.push(user);
                }
            })
            if (stop.address) {
                newStops.push(shortenAddress(stop.address));
            }
        })
        if (newPassengers.length > 0) {
            for (let i = 0; i < newPassengers.length; i++) {
                passengersText += " & " + (newPassengers[i].first_name ? (newPassengers[i].first_name + ' ') : '') + newPassengers[i].name + " " + newPassengers[i].phone;
            }
        }
        if (newStops.length > 0) {
            for (let i = 0; i < newStops.length; i++) {
                stopsText += "\nArrêt n°" + (i + 1) + " " + newStops[i];
            }
        }
    }

    let text = "VOTRE " + (isClient ? "RÉSERVATION" : "MISSION") + " - SERVICE COMMANDÉ : " + (ride.service === 'VSL' ? "TAXI" : ride.service) + "\n-------------------------\n";
    //Only for MBHT CAB
    if (ride.driver_group._id === '635437e657f3da0bad48dacd') {
        text = "SERVICE DE TRANSPORT PUBLIC DE PERSONNES - BILLET COLLECTIF ------- (Arrêté du 14 février 1986 – Article.5) et ordre de mission (Arrêté du 6 janvier 1993 – Article 3) ----- LOCATION DE VÉHICULE AVEC CHAUFFEUR\n\n";
    }
    text += "Date et heure : " + moment(ride.departure_date).format("dddd DD/MM/YY [à] HH[h]mm");
    text += "\n\nDépart : " + ride.departure_address + stopsText + "\nDestination : " + ride.arrival_address;
    text += "\n\nClient : " + ride.passenger.first_name + " " + ride.passenger.name + " " + (ride.passenger.phone ? ride.passenger.phone : "") + " (" + ride.nb_passenger + " pax) " + passengersText;

    if (ride.driver_unit) {
        text += "\n\nChauffeur : " + ride.driver_unit.first_name + " " + ride.driver_unit.name.substring(0, 1) + ". " + ride.driver_unit.phone;
        let vehicles = [];
        if (ride.driver_unit && ride.driver_unit.driver_unit && ride.driver_unit.driver_unit.vehicles && ride.driver_unit.driver_unit.vehicles.length > 0) {
            vehicles = ride.driver_unit.driver_unit.vehicles.filter(vhl => vhl.selected);
        }
        if (vehicles.length > 0) text += "\nVéhicule : " + vehicles[0].brand + ' ' + vehicles[0].model + ' ' + vehicles[0].color + (vehicles[0].license_plate ? ' (' + vehicles[0].license_plate + ')' : '');
    }

    if (ride.price_client && isClient) {
        text += "\n\nTarif : " + ride.price_client + " € TTC " + (ride.invited ? "paiement sur facture" : "paiement à bord (CB ou espèces)");
    } else if (!isClient) {
        text += "\n\nTarif sous-traitant : " + (ride.price_sc ? ride.price_sc : "??? ") + " € HT " + (ride.invited || ride.paid_client ? "paiement sur facture (pas d'encaissement)" : "paiement à bord (CB ou espèces) de " + (ride.price_client || "??") + " € TTC ");
    }

    if (ride.comment_public || ride.flight_train_number) text += "\n\nInfos : " + (ride.comment_public || "") + " " + (ride.flight_train_number || "");
    if (ride.comment_private && !isClient) text += " " + (ride.comment_private || "");

    text += "\n\nNous vous remercions pour votre confiance.\n-------------------------\n";

    if (ride.driver_group.social_networks && ride.driver_group.social_networks.website) {
        text += ride.driver_group.social_networks.website + "\n";
    }

    text += ride.driver_group.brand + "\nEn cas de besoin : " + ride.driver_group.support_phone + " ou " + ride.driver_group.support_email;
    if (ride.driver_group._id === '635437e657f3da0bad48dacd') {
        text += "\n\nPaiement 48h00 maximum à réception facture. \nFacture à envoyer obligatoirement par mail à facture@global-sphere.fr.\nInfo de facturation = GLOBAL SPHERE, 25 rue de ponthieu, 75008 Paris";
    }
    return text;
}