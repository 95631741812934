/**
 * Correspondance Format - Nombre de pixels
 */
const Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export default Breakpoints;
