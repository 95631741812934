// @flow
import React from 'react';
import { withToastManager } from 'react-toast-notifications';
// Traduction
import { injectIntl } from 'react-intl';
import DriverGroup from '../../DriverGroup';

class YouCallYouGo extends React.Component<Props, State> {
  render() {

    return <DriverGroup {...this.props} displayHeaderFooter={true} driver_group="6540e0d89bfd116709a6c771"/>
  }
}

export default injectIntl(withToastManager(YouCallYouGo));
