// @flow

import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import { MDBRow } from 'mdbreact';

type Props = {
  data: Object,
  intl: Object
};

class DriverAndStatus extends PureComponent<Props> {
  render() {
    const { data, showDriver, showDriverGroup, showStatus, intl, user } = this.props;

    if (!showDriver && !showDriverGroup && !showStatus) {
      return null;
    }

    return (
      <MDBRow className="d-flex justify-content-between ml-2 mr-3 mt-0" style={{ width: '90%' }}>
        {showDriver && data.driver_unit && data.driver_unit.name && (
          <div className="DriverBadge TextTravelCard">
            {data.driver_unit.first_name} {data.driver_unit.name.substring(0, 2)}
            {'.'}
          </div>
        )}
        {showDriverGroup && <div className="DriverBadge TextTravelCard">{data.driver_group.brand}</div>}
        {showStatus && data.status && (
          <div className="TextTravelCard pt-1 ml-auto">
            {intl.formatMessage({ id: `${user && user.roles.includes('manager') ? 'manager' : 'passenger'}.rides.state.${data.logs && data.logs.length > 0 && user.roles.includes('manager') ? data.logs : data.status}` })}
            <i key="cell1" className={`fas fa-circle ml-2 status-${data.status.toLowerCase()}`} aria-hidden="true" />
          </div>
        )}
      </MDBRow>
    );
  }
}

export default injectIntl(DriverAndStatus);
