import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 1.333c3.667 0 6.667 3 6.667 6.667s-3 6.667-6.667 6.667-6.667-3-6.667-6.667 3-6.667 6.667-6.667zm-.133 1.334v1.2a2.948 2.948 0 0 0-1.734.733c-.467.467-.667.934-.667 1.6 0 .667.2 1.2.6 1.6.4.333 1.067.666 1.934 1 .4.133.666.334.8.467.133.133.2.4.2.667 0 .266-.067.467-.2.6-.134.133-.334.2-.667.2-.4 0-.666-.068-.866-.268-.2-.2-.333-.532-.333-.932H9.066v.066c0 .867.267 1.466.733 1.866.467.4 1.134.668 1.868.735v1.132h.867v-1.066c.733-.067 1.333-.267 1.733-.667.4-.4.6-.933.6-1.6 0-.733-.266-1.267-.666-1.667-.4-.4-1.067-.733-1.934-1-.4-.2-.668-.4-.8-.534-.134-.133-.2-.332-.2-.598 0-.267.066-.467.2-.667.132-.2.332-.267.666-.267.266 0 .466.134.666.267s.267.4.267.8h1.868c0-.667-.2-1.2-.6-1.667-.4-.467-1-.733-1.667-.8v-1.2h-.8z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0B060D',
};

export default SvgComponent;
