import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        stroke={color || defaultColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.5053436,4 L12.5053436,5.36266479 C15.9013598,5.36266479 18.6373352,8.0986402 18.6373352,11.4946564 L20,11.4946564 C20,7.36407871 16.6359213,4 12.5053436,4 Z M12.5053436,6.72532958 L12.5053436,8.08799438 C14.3949768,8.08799438 15.9120056,9.60502388 15.9120056,11.4946564 L17.2746704,11.4946564 C17.2746704,8.86780043 15.1321996,6.72532958 12.5053436,6.72532958 Z M6.65014336,6.7892045 L6.18172734,7.25762052 C3.27275755,10.1665903 3.27275755,14.9093029 6.18172734,17.8182727 C9.09069713,20.7272424 13.8334097,20.7272424 16.7423795,17.8182727 L17.2107955,17.3498566 L16.7423795,16.860149 L12.9950513,13.1128208 C13.6923528,12.8999044 14.2086746,12.2638166 14.2086746,11.4946564 C14.2086746,10.555163 13.444837,9.79132537 12.5053436,9.79132537 C11.7361834,9.79132537 11.1000956,10.3076479 10.8871792,11.0049487 L7.13985102,7.25762052 L6.65014336,6.7892045 Z M6.75660155,8.81191005 L15.18809,17.2433985 C12.7901195,19.1463387 9.35950456,19.0798025 7.13985102,16.860149 C4.92019817,14.6404961 4.85366129,11.2098805 6.75660155,8.81191005 Z"        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
