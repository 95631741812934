// @flow

import React, { Fragment } from 'react';

// UI
import CardTitle from '../Generic/CardTitle';

import { IconPhone, IconEmail, IconWebsite } from '../../../assets';

type Props = {
  dg: Object
};

class Description extends React.PureComponent<Props> {
  generateLink = (item: Object) => {
    switch (item.icon) {
      case 'phone':
        return `tel:${item.value}`;
      case 'email':
        return `mailto:${item.value}`;
      default:
        return item.value;
    }
  };

  renderPicto = (item: Object) => {
    switch (item.icon) {
      case 'phone':
        return <IconPhone />;
      case 'email':
        return <IconEmail />;
      case 'web':
        return <IconWebsite />;
      default:
        return null;
    }
  };

  renderContacts = () => {
    const { dg } = this.props;

    const contacts = [];

    if (dg.support_phone) {
      contacts.push({
        icon: 'phone',
        value: dg.support_phone
      });
    }
    if (dg.support_email) {
      contacts.push({
        icon: 'email',
        value: dg.support_email
      });
    }
    if (dg.social_networks && dg.social_networks.website) {
      contacts.push({
        icon: 'web',
        value: dg.social_networks.website
      });
    }

    return (
      <Fragment>
        {contacts.map(item => (
          <div className="Contact" key={item.icon}>
            <div className="Container">
              {this.renderPicto(item)}
             <a href={this.generateLink(item)}>{item.value}</a>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  render() {
    const { dg } = this.props;

    return (
      <div className="Description Card" id="description">
        <CardTitle autoWidth title="driver-group.titles.description" />
        <div className="Text">{dg.description}</div>
        <div className="Contacts">{this.renderContacts()}</div>
      </div>
    );
  }
}

export default Description;
