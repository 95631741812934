import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M15.77 7c.552 0 1.065.296 1.358.783l.01.017 1.145 2.533h.117c.88 0 1.6.75 1.6 1.667v2.333c0 .549-.434 1-.96 1h-.67c-.153.943-.943 1.667-1.89 1.667s-1.737-.724-1.89-1.667H9.41C9.258 16.276 8.468 17 7.52 17s-1.737-.724-1.89-1.667h-.67c-.526 0-.96-.451-.96-1v-1.87c0-.813.568-1.51 1.337-1.644l2.314-.454 1.785-2.693.004-.005A1.58 1.58 0 0 1 10.72 7h5.05zm0 .667H13.6v2.666h.757c.104-.228.276-.415.49-.532a.841.841 0 0 1-.287-.634c0-.119.024-.231.066-.333a.8.8 0 0 1 .734-.5c.442 0 .8.372.8.833a.841.841 0 0 1-.288.634c.215.117.387.304.491.532h1.213l-.996-2.2a.95.95 0 0 0-.81-.466zm-2.81 0h-2.24a.947.947 0 0 0-.767.398L8.45 10.333h1.747c.104-.228.276-.415.49-.532a.857.857 0 0 1-.224-.962.801.801 0 0 1 .737-.506c.442 0 .8.373.8.834a.841.841 0 0 1-.288.634c.215.117.387.304.491.532h.757V7.667zM18.4 11H7.869l-2.423.476h-.004a.986.986 0 0 0-.802.987v1.87c0 .189.14.334.32.334h.67C5.782 13.724 6.572 13 7.52 13s1.737.724 1.89 1.667h5.18c.153-.943.943-1.667 1.89-1.667s1.737.724 1.89 1.667h.67c.18 0 .32-.145.32-.334V12c0-.556-.426-1-.96-1zm-1.92 2.667c-.71 0-1.28.593-1.28 1.333s.57 1.333 1.28 1.333c.71 0 1.28-.593 1.28-1.333s-.57-1.333-1.28-1.333zm-8.96 0c-.71 0-1.28.593-1.28 1.333s.57 1.333 1.28 1.333c.71 0 1.28-.593 1.28-1.333s-.57-1.333-1.28-1.333z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
