import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
    size,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <path
        d="M11.25 11.25V4.727c0-.401.336-.727.75-.727s.75.326.75.727v6.523h6.523c.401 0 .727.336.727.75s-.326.75-.727.75H12.75v6.523A.739.739 0 0112 20a.739.739 0 01-.75-.727V12.75H4.727A.739.739 0 014 12c0-.414.326-.75.727-.75h6.523z"
        fill={color || defaultColor}
        fillRule="nonzero"
        stroke={color || defaultColor}
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
  size: 24,
};

export default SvgComponent;
