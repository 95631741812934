import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M17.993 17.864v-2.038c0-1.32-.559-2.505-1.44-3.32l.191-.352a.397.397 0 00-.142-.53.368.368 0 00-.511.148l-.144.265a4.14 4.14 0 00-2.244-.665h-2.405c-2.366 0-4.29 1.998-4.29 4.455v2.038c-.002.014-.008.027-.008.042 0 .239.186.433.417.433.23 0 .416-.194.416-.433h.008v-2.08c0-1.979 1.55-3.59 3.457-3.59h2.405a3.34 3.34 0 011.835.554l-1.03 1.896-.002.004a1.013 1.013 0 00-.326-.058c-.591 0-1.07.497-1.07 1.11 0 .253.085.484.222.671a.39.39 0 00-.076.091l-1.583 2.914a.398.398 0 00.142.53c.058.035.122.051.184.051.13 0 .258-.071.327-.198l1.583-2.914c.012-.021.014-.044.021-.066.08.02.163.033.25.033.59 0 1.069-.497 1.069-1.11 0-.214-.06-.412-.162-.581a.404.404 0 00.073-.093l.973-1.79a3.646 3.646 0 011.026 2.549v2.079h.007c0 .239.187.433.417.433.23 0 .417-.194.417-.433.001-.018-.005-.031-.007-.045zM14.18 16.38a.629.629 0 01-.616-.64c0-.353.277-.64.616-.64.34 0 .617.287.617.64 0 .353-.277.64-.617.64zM12.5 10.89c-1.829 0-3.317-1.546-3.317-3.445 0-1.9 1.489-3.445 3.318-3.445 1.828 0 3.317 1.546 3.317 3.445 0 1.9-1.488 3.445-3.317 3.445zm0-6.024c-1.369 0-2.483 1.157-2.483 2.58 0 1.421 1.114 2.578 2.484 2.578 1.369 0 2.483-1.156 2.483-2.579 0-1.422-1.114-2.58-2.483-2.58z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
