import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = (props) => {
  const {
    color,
  } = props;

  const defaultColor = '#0B060D';
  return (
    <svg width={24} height={24} {...props}>
      <path
        d="M9.443 4v1.524h4.442V4H9.443zm7.373 1.366l-.957 1.162 2.184 1.905L19 7.271l-2.184-1.905zm-5.152.92C7.978 6.286 5 9.35 5 13.143 5 16.935 7.978 20 11.664 20c3.685 0 6.664-3.065 6.664-6.857 0-3.792-2.979-6.857-6.664-6.857zm0 1.524c2.895 0 5.183 2.353 5.183 5.333s-2.288 5.333-5.183 5.333c-2.896 0-5.183-2.354-5.183-5.333 0-2.98 2.287-5.333 5.183-5.333zm-.74 1.523v4.572h1.48V9.333h-1.48z"
        fill={color || defaultColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

SvgComponent.propTypes = {
  color: PropTypes.string,
};

SvgComponent.defaultProps = {
  color: '#0b060d',
};

export default SvgComponent;
