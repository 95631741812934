// @flow

import React from 'react';

import {
    IconPlus,
    IconLess
} from '../../../../../assets';


import Services from './../Services';

type Props = {
    intl: Object,
    dg: Object,
    booking: Object,
    activeTab: String,
    setPoint: Function,
    setService: Function,
    handleBookingInputChange: Function,
    increasePassenger: Function
};

class PassengerMode extends React.PureComponent<Props, State> {
    render() {
        const {
            intl,
            dg,
            booking,
            activeTab,
            setPoint,
            setService,
            handleBookingInputChange,
            increasePassenger
        } = this.props;

        return <div className="Address">
          <div className="Passenger">
            <div className="Label">
              {intl.formatMessage({
                id: 'driver-group.booking.form.passenger'
              })}
            </div>
            <div className="Input-Group">
              <div
                className="Button Left"
                onClick={() => increasePassenger(true)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                <IconPlus />
              </div>
              <input
                type="number"
                name="nb_passenger"
                min={1}
                value={booking.nb_passenger}
                />
              <div
                className="Button Right"
                onClick={() => increasePassenger(false)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                <IconLess />
              </div>
            </div>
          </div>
          <div className="Services">
            <div className="Label">
              {intl.formatMessage({ id: 'driver-group.booking.form.services' })}
            </div>
            <Services
              intl={intl}
              dg={dg}
              booking={booking}
              setService={setService}
            />
          </div>
        </div>
    }
}

export default PassengerMode;
