/**
 * Invoice naming function for download
 * @param {Object} invoice invoice
 */
export default function exportNameInvoice(
    invoice: Object
) {
    let filename = invoice.title;

    filename = filename.replace(/\//g, "_");
    filename = filename.replace(/\s/g, '');
    filename = filename.replace(/\./g, '');

    return filename;
}
