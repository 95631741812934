// @flow
import React from 'react';

import DateFnsUtils from '@date-io/moment'; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core';

import 'react-dates/lib/css/_datepicker.css';
import { getMuiTheme } from '../../../../../modules/materialTheme';
import { MDBCol, MDBTooltip, MDBIcon, MDBRow } from 'mdbreact';
import moment from 'moment';

type Props = {
  intl: Object,
  dg: Object,
  booking: Object,
  activeTab: String,
  setPoint: Function,
  setService: Function,
  handleBookingInputChange: Function,
  increasePassenger: Function
};

class Time extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isDepartureTimePickeOpen: false,
      isReturnTimePickeOpen: false,
      isWayBackActivated: false,
    };
    this.datepicker = React.createRef();
  }

  renderReturnButton = () => {
    const {
      intl,
      booking,
      handleBookingInputChange,
      quotation
    } = this.props;

    return <div className='pt-2'>
      <div>
        {intl.formatMessage({
          id: 'driver-group.booking.form.return_date'
        })}
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <DateTimePicker
            variant="inline"
            minutesStep={5}
            label="Retour"
            format="DD/MM/YYYY HH:mm"
            autoOk
            ampm={false}
            showtabs="false"
            autosubmit="false"
            allowKeyboardControl
            minDate={booking.departure_date}
            name="return_date"
            value={booking.return_date ? booking.return_date : booking.departure_date}
            onChange={(value) => handleBookingInputChange({
              target: { type: 'string', name: 'return_date', value: value }
            })}
            onClose={() => {
              quotation("return_date");
              this.setState({ isReturnTimePickeOpen: false });
            }}
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  }

  renderPrices = () => {
    const {
      intl,
      booking,
      estimatePrice, 
      dg
    } = this.props;

    return <div className=''>
      {booking.price_client ? <div className='PriceAndCTA'>
        <div className={`PriceBloc ${booking.price_client ? '' : 'hidden'}`}>
          <div>
            {intl.formatMessage({ id: 'driver-group.booking.form.price_client' })}
          </div>
          <div>{booking.price_client} {dg.currency}</div>
        </div>
      </div> : <div className='PriceAndCTA'>
        <div className="PriceBloc">
          <div>
            {intl.formatMessage({ id: 'driver-group.booking.form.estimate' })}
          </div>
          <div>{estimatePrice ? estimatePrice : "..."}</div>
        </div>
      </div>}
    </div>
  }

  render() {
    const { intl, booking, handleBookingInputChange, invited, quotation } = this.props;
    const { isWayBackActivated } = this.state;


    return <div className="Address">
      {/** Affichage des prix */}
      {invited ? null : this.renderPrices()}

      {/** Affichage de la date de départ */}
      <div className="StartDate">
        <div>
          {intl.formatMessage({ id: 'driver-group.booking.form.departure_date' })}
        </div>
        <MDBRow>
          <MDBCol size="10">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <DateTimePicker
                  label={isWayBackActivated ? "Aller" : ""}
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  autoOk
                  minutesStep={5}
                  ampm={false}
                  showtabs="false"
                  autosubmit="false"
                  allowKeyboardControl
                  minDate={new Date()}
                  key="departure_date"
                  name="departure_date"
                  value={booking.departure_date ? booking.departure_date : null}
                  onChange={date => {
                    handleBookingInputChange({
                      target: {
                        name: 'departure_date',
                        value: date
                      }
                    });
                  }}
                  onClose={() => {
                    quotation();
                  }}
                />
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </MDBCol>
          {!isWayBackActivated ? <MDBCol
            size="2"
            className="d-flex justify-content-center align-items-center fa-lg"
            onClick={() => this.setState({ isWayBackActivated: !isWayBackActivated })}
          >
            <MDBTooltip
              domElement
              tag="span"
              placement="top"
            >
              <div>
                <MDBIcon icon="exchange-alt" />
              </div>
              <span>Réserver le retour</span>
            </MDBTooltip>
          </MDBCol> : <MDBCol
            size="2"
            className="d-flex justify-content-center align-items-center fa-lg"
            onClick={() => isWayBackActivated ?
              this.setState({ isWayBackActivated: false, isDateTimePickeOpen: false },
                () => handleBookingInputChange({
                  target: { type: 'string', name: 'return_date', value: null }
                })) :
              this.setState({ isWayBackActivated: true, isDateTimePickeOpen: true })}>
            {isWayBackActivated && <MDBTooltip
              domElement
              tag="span"
              placement="top"
            >
              <div>
                <MDBIcon icon="times" />
              </div>
              <span>Annuler le retour</span>
            </MDBTooltip>}
          </MDBCol>}
        </MDBRow>

      </div>
      
      {/** Affichage de la date de retour */}
      <div className="StartDate">
        {isWayBackActivated ? this.renderReturnButton() : null}
      </div>
    </div>;
  }
}

export default Time;
